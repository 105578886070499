:root {
	--black: #1a1a1a;
	--blue: #00549d;
	--white: white;
	--gold: #946f05;
	--new-gold: #946f05;
	--black2: #000;
	--off-black: #545454;
	--bonus-green: #09740e;
	--honeydew: #dee9dd;
	--dark-gold: #6d5409;
	--medium-sea-green: #419b45;
	--light-gold: #b78c09;
}

body {
	color: var(--black);
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;
}

h2 {
	color: #475872;
	letter-spacing: 0.02rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 1.875rem;
	font-weight: 600;
	line-height: 2.375rem;
}

img {
	max-width: 100%;
	display: inline-block;
}

.navbar__logo-link {
	max-width: 345px;
}

.primary-button {
	border: 2px solid var(--blue);
	background-color: var(--blue);
	color: #fff;
	text-align: center;
	letter-spacing: 0;
	text-transform: none;
	border-radius: 0;
	margin-top: 2rem;
	margin-right: 1rem;
	padding: 12px 32px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 0.95rem;
	text-decoration: underline;
	transition: all 0.3s, transform 0.3s;
	display: inline-block;
}

.primary-button:hover {
	border: 2px solid var(--blue);
	background-color: var(--white);
	box-shadow: none;
	color: var(--blue);
	text-decoration: none;
}

.quick-links__card {
	grid-row-gap: 1rem;
	background-color: var(--white);
	box-shadow: none;
	text-align: center;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 1rem;
	display: flex;
}

.footer-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 1.75rem;
	background-color: var(--blue);
	color: var(--white);
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	align-items: center;
	width: 100%;
	margin-top: 0;
	padding-top: 3rem;
	font-family: Roboto, sans-serif;
	display: flex;
}

.footer-wrapper.footer-section {
	background-color: #22262f;
	flex-direction: column;
	justify-content: center;
	padding: 2rem 0 0;
}

.quick-links__icon {
	width: 4rem;
	height: 4rem;
}

.paragraph {
	color: #525252;
	text-align: center;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-family: Open Sans, sans-serif;
	font-size: 0.95rem;
	text-decoration: none;
}

.paragraph.small {
	text-align: left;
	padding-top: 0;
	padding-left: 0;
	font-size: 1rem;
}

.about-us__text {
	flex-flow: column;
	flex: 0 50%;
	justify-content: center;
	align-items: flex-start;
	display: flex;
}

.about-us__text.out,
.about-us__text.mobile {
	display: none;
}

.about-us__text.mobile-view {
	justify-content: center;
	align-items: flex-start;
}

.about-us__img-wrap {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	flex: 0 50%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.footer-content {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	width: 100%;
	display: grid;
}

.footer-content.fdic {
	display: block;
	text-align: center;
	border-bottom: 1px solid #fff;
	margin-top: 1rem;
	padding-bottom: 1.75rem;
}

.footer-content.fdic img {
	max-height: 22px;
}

.footer-content.signoff-container {
	background-color: #22262f;
	border-bottom-style: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: none;
	margin-top: 0;
	padding: 2rem 12vw 1.5rem;
	display: flex;
}

.footer-left-content-wrapper {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;
	padding-left: 0;
	display: flex;
}

.footer-list-item-link.w--current {
	color: #f9b918;
	border-bottom: 1px solid #888;
}

.footer-right-content-wrap {
	justify-content: space-between;
	width: 100%;
	padding-right: 0;
	display: flex;
}

.footer-signoff-link:hover {
	border-bottom: 1px none var(--white);
	opacity: 1;
	color: var(--white);
}

.footer-signoff-link.w--current {
	color: #9fb833;
}

.footer-signoff-link.first-signoff-link {
	margin-left: 0;
	margin-right: 0.25rem;
}

.c-fdic__img {
	width: 80px;
	height: auto;
}

.cb-heading {
	margin-top: 0;
	margin-bottom: 1rem;
	font-size: 2.286rem;
	font-weight: 400;
	line-height: 2.986rem;
	display: inline-block;
}

.cb-heading.footer-heading {
	letter-spacing: 0;
	text-transform: none;
	border-bottom: 2px rgba(255, 255, 255, 0.2);
	margin-bottom: 0.25rem;
	font-family: Inter;
	font-size: 1.05rem;
	font-weight: 700;
	line-height: 2.5rem;
	display: block;
}

.signoff__grip-text {
	color: var(--white);
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.signoff__grip-text.webdevby-white {
	color: #fff;
}

.footer-content-wrapper {
	width: 100%;
	max-width: 1440px;
	padding-left: 2rem;
	padding-right: 2rem;
}

.signoff__grip-link {
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 150px 29px;
	width: 140px;
	min-width: 0;
	height: 29px;
	margin-top: 0;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.signoff__grip-link.white-logo {
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
	width: 140px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
}

.signoff__p {
	margin-bottom: 0;
	font-size: 0.8rem;
}

.signoff__p h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.footer-section {
	background-color: #444;
}

.footer-content__item {
	grid-row-gap: 1.5rem;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.footer-content__item.footer-cb-left {
	flex-direction: column;
	align-items: stretch;
	width: auto;
	margin-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
	display: flex;
}

.footer-content__item.footer-logo-left {
	flex-direction: column;
	align-items: flex-start;
	width: auto;
	margin-right: 0;
	padding-right: 0;
	display: flex;
}

.footer-content__item.is--centered {
	grid-column-gap: 11px;
	grid-row-gap: 11px;
	text-align: center;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 60px;
}

.footer-nav-list {
	margin-bottom: 0;
	padding-left: 0;
}

.link {
	opacity: 0.75;
	font-family: Inter;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 0.9rem;
	text-decoration: none;
	transition: opacity 0.2s;
}

.link:hover {
	opacity: 1;
}

.div-block-68 {
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	margin-top: 0.6rem;
	display: flex;
}

.footer-link-wrapper {
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.footer-link-wrapper.first {
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.85rem;
}

.footer-icon-block {
	background-color: #aa1e47;
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	width: 1.65rem;
	height: 1.65rem;
	margin-right: 0.5rem;
	display: flex;
}

.phone-icon {
	width: 1rem;
}

.location-icon {
	width: 1.1rem;
}

.member-fdic-img {
	height: 25px;
	margin-left: 0;
	margin-right: 0;
}

.resources-list-item {
	background-color: rgba(0, 0, 0, 0);
	border: 1px #ececec;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	width: 31%;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	transition: box-shadow 0.2s;
	display: flex;
}

.resources-list-item:hover {
	box-shadow: 0 8px 10px -7px rgba(82, 82, 82, 0.65);
}

.mega-menu__subnav {
	border-right: 0 solid rgba(82, 82, 82, 0.08);
	flex-flow: column wrap;
	flex: 1;
	align-items: flex-start;
	padding: 0.55rem 0.75rem 0;
	display: flex;
}

.mega-menu__subnav.is--last {
	border-right-style: none;
}

.quick-links__img {
	width: 4rem;
}

.invisible-block {
	z-index: 5000;
	height: 130px;
}

.mega-menu {
	z-index: 10000;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;
	max-width: none;
	margin-left: auto;
	margin-right: auto;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	display: flex;
}

.footer-divider-line {
	background-color: rgba(255, 255, 255, 0.15);
	width: 35%;
	height: 1px;
}

.social-cta__content {
	color: var(--white);
	text-align: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.breadcrumb-divider {
	float: right;
	color: #333;
	margin-left: 0.5rem;
	font-size: 0.75rem;
	font-weight: 700;
}

.image-banner {
	background-color: #00549d;
	height: auto;
	margin-top: 0;
}

.image-banner.travel-with-debit-card {
	background-image: url('/20181016082423/assets/images/travel-debit-card-banner-image.jpg');
	background-position: 50% 30%;
}

.main-content-column {
	padding-left: 0;
	padding-right: 0;
}

.breadcrumb-list {
	text-transform: uppercase;
	margin: 0 0.125rem;
	padding-left: 0;
	font-size: 0.6875rem;
	font-weight: 300;
	line-height: 1em;
}

.left-nav-column {
	border-top: 1px rgba(91, 91, 91, 0.15);
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.table-div {
	font-family: Roboto, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
	display: none;
}

.div-block-140 {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.div-block-140.inside-page {
	width: auto;
	height: auto;
	line-height: 55px;
}

.intro-paragraph {
	color: #333;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.ordered-list {
	margin-bottom: 1rem;
	padding-left: 20px;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.block-quote {
	border-left-width: 0;
	border-left-color: var(--gold);
	background-color: var(--new-gold);
	color: var(--white);
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	font-family: Roboto, sans-serif;
	font-size: 21px;
	font-weight: 700;
	line-height: 1.75rem;
}

.h5-heading-link {
	color: var(--black2);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h5-heading-link:hover {
	border-bottom-color: var(--new-gold);
	color: var(--new-gold);
	background-color: rgba(0, 0, 0, 0);
}

.heading-18 {
	color: var(--black2);
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.heading-18.second {
	margin-top: 40px;
	display: none;
}

.heading-18.tables {
	margin-top: 40px;
}

.inside-content-background {
	background-color: #fff;
	border-top: 1px #000;
	max-width: 1140px;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0 0 3rem;
	display: block;
	position: static;
}

.section-wrap {
	background-color: rgba(0, 0, 0, 0);
	width: 100%;
	padding-bottom: 0;
	overflow: hidden;
}

.left-nav-list {
	border-top: 1px rgba(91, 91, 91, 0.15);
	border-right: 1px solid rgba(91, 91, 91, 0.15);
	margin-top: 0;
	margin-bottom: 1.5rem;
	padding-left: 0;
}

.h3-heading-link {
	color: var(--black2);
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3-heading-link:hover {
	border-bottom-color: var(--new-gold);
	color: var(--new-gold);
	-webkit-text-stroke-color: var(--new-gold);
	background-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

.content-container {
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 50%;
	max-width: none;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.content-container.main-content {
	width: 100%;
	max-width: none;
	padding-left: 0;
	padding-right: 0;
}

.h6-header-link {
	color: var(--black2);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link:hover {
	border-bottom-color: var(--new-gold);
	color: var(--new-gold);
	background-color: rgba(0, 0, 0, 0);
}

.text-block-21 {
	color: var(--new-gold);
	text-transform: none;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	line-height: 0.85rem;
}

.h2-heading-link {
	color: #525252;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.875rem;
	font-weight: 700;
	line-height: 2.375rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h2-heading-link:hover {
	color: #aa1e47;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(136, 136, 136, 0.1);
}

.breadcrumb-list-item {
	float: left;
	margin-top: 0;
	margin-bottom: 0.25rem;
	margin-right: 0.5rem;
}

.main-content-section {
	border-top: 0 solid rgba(91, 91, 91, 0.15);
	max-width: none;
	padding-top: 1rem;
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.breadcrumb-link {
	color: var(--black2);
	text-transform: none;
	border-bottom: 1px #888;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	line-height: 0.85rem;
	text-decoration: none;
}

.breadcrumb-link:hover {
	color: var(--new-gold);
}

.paragraph-text {
	color: var(--off-black);
	margin-bottom: 1.5rem;
	padding-right: 20px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-text.list-item {
	margin-bottom: 1rem;
}

.left-nav-list-content-wrap {
	border-right: 1px rgba(91, 91, 91, 0.15);
	height: auto;
	margin-top: 0;
	margin-right: 1rem;
	padding-bottom: 0;
	display: block;
	overflow: hidden;
}

.imagecon {
	background-image: url('/20181016082423/assets/images/GettyImages-1180123651.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
}

.imagecon.recent-blog-post {
	background-image: none;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: auto;
	padding: 0 10px 10px;
	display: flex;
}

.left-nav-nested-list {
	background-color: rgba(255, 255, 255, 0.15);
	border-top: 2px #498fa3;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.left-nav-nested-list.great-grandchild-link {
	background-color: #fafafa;
}

.horiz-divider {
	color: #003c43;
	background-color: rgba(91, 91, 91, 0.15);
	height: 1px;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.main-content-wrapper {
	width: 100%;
	max-width: none;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0%;
	padding-left: 15px;
	padding-right: 15px;
	display: block;
}

.inside-page-header-wrap {
	border-bottom: 1px rgba(91, 91, 91, 0.15);
	max-width: 1140px;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	padding-left: 15px;
}

.button-styling {
	align-items: center;
	padding-left: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 800;
	display: flex;
}

.button-styling:hover {
	text-decoration: none;
}

.left-nav-nested-list-item {
	box-shadow: none;
	border-bottom: 1px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 0;
}

.left-nav-nested-list-item.great-grandchild-wrapper {
	border-bottom-style: none;
}

.left-nav-list-link {
	border-top: 2px solid var(--white);
	border-bottom: 1px solid var(--white);
	color: var(--black2);
	text-transform: none;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-family: Roboto, sans-serif;
	font-size: 0.925rem;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
}

.left-nav-list-link:hover {
	color: var(--new-gold);
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding-left: 1rem;
	text-decoration: underline;
}

.left-nav-list-item {
	border-bottom: 1px solid rgba(91, 91, 91, 0.15);
	flex-direction: row;
	margin-bottom: 0;
	display: block;
}

.left-nav-list-item.grandchild-wrapper {
	border-bottom-style: none;
}

.left-nav-nested-list-link {
	color: #333;
	text-transform: capitalize;
	border: 2px rgba(0, 0, 0, 0);
	padding: 0.75em 1.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.85em;
	font-weight: 500;
	line-height: 1.375em;
	text-decoration: none;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	border: 2px #48bdd7;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild {
	color: var(--black);
	text-transform: none;
	border: 1px #000;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild:hover {
	color: var(--new-gold);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding-left: 1.5rem;
	text-decoration: underline;
}

.left-nav-nested-list-link.great-grandchild {
	border-top: 2px solid var(--white);
	border-bottom: 2px solid var(--white);
	color: var(--black);
	padding-left: 2.25rem;
	font-family: Roboto, sans-serif;
	font-size: 0.8rem;
	font-weight: 500;
	transition: all 0.35s;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: var(--new-gold);
}

.unordered-list {
	margin-bottom: 1rem;
}

.h4-heading {
	color: var(--black2);
	text-align: left;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.h3-heading {
	color: var(--black2);
	margin-top: 10px;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
}

.h3-heading.section-title {
	background-color: rgba(250, 250, 250, 0);
	margin-top: 0;
	margin-bottom: 0;
	padding: 1rem;
	border-right: 1px solid rgba(91, 91, 91, 0.15);
}

.h2-heading {
	border-top: 0px none var(--gold);
	color: var(--black2);
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 46px;
	font-weight: 700;
	line-height: 55px;
	text-decoration: none;
}

.h1-heading {
	color: var(--black2);
	text-align: left;
	margin-top: 0;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 46px;
	font-weight: 700;
	line-height: 55px;
}

.h5-heading,
.h6-heading {
	color: var(--black2);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.h4-heading-link {
	color: #525252;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	display: inline-block;
}

.h4-heading-link:hover {
	border-bottom-color: var(--new-gold);
	color: var(--new-gold);
}

.secondary-button {
	border: 2px solid var(--new-gold);
	background-color: var(--new-gold);
	color: #fff;
	text-align: center;
	letter-spacing: 0;
	text-transform: none;
	border-radius: 0;
	margin-top: 2rem;
	margin-right: 1rem;
	padding: 12px 32px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 0.95rem;
	text-decoration: underline;
	transition: all 0.3s, transform 0.3s, background-color 0.3s;
	display: inline-block;
}

.secondary-button:hover {
	background-color: var(--white);
	color: var(--new-gold);
	text-decoration: none;
}

.tertiary-button {
	color: #fff;
	letter-spacing: 0;
	text-transform: none;
	background-color: #525252;
	border: 2px solid #525252;
	border-radius: 0;
	margin-top: 2rem;
	margin-right: 1rem;
	padding: 12px 32px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 0.95rem;
	text-decoration: underline;
	transition: all 0.3s, box-shadow 0.3s, transform 0.3s, background-color 0.3s;
	display: inline-block;
}

.tertiary-button:hover {
	background-color: var(--white);
	color: #525252;
	text-decoration: none;
}

.paragraph-text-link {
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.2) var(--blue);
	color: var(--blue);
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
	display: inline;
}

.paragraph-text-link:hover {
	color: var(--blue);
	border-bottom-color: rgba(0, 0, 0, 0);
	font-family: Roboto, sans-serif;
}

.paragraph-text-link.right-sidebar-row {
	cursor: pointer;
	margin-top: 1rem;
	margin-left: 0;
	font-size: 1rem;
	display: inline-block;
}

.link-2 {
	text-decoration: none;
}

.main-col {
	padding-left: 0;
	padding-right: 0;
}

.right-col {
	border-top: 1px rgba(91, 91, 91, 0.15);
}

.right-sidebar {
	border-left: 1px solid rgba(91, 91, 91, 0.15);
	border-right: 6px #edb761;
	height: 100%;
	margin-top: 0;
	margin-left: 0;
	padding: 2rem 0 0 1.5rem;
}

.right-nav-link {
	color: var(--off-black);
	border-bottom: 0 solid rgba(91, 91, 91, 0.15);
	margin-left: 1rem;
	margin-right: 0;
	padding: 0.75rem 0 0;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	text-decoration: underline;
	transition: all 0.2s;
	display: inline;
	position: relative;
}

.right-nav-link:hover {
	color: var(--blue);
	text-decoration: none;
}

.right-nav-link.w--current {
	border-bottom-color: #edb761;
}

.right-sidebar-wrapper {
	margin-bottom: 0.5rem;
}

.right-sidebar-row {
	margin-bottom: 1rem;
}

.h1-heading-link {
	color: #525252;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-family: Inter;
	font-size: 38px;
	font-weight: 700;
	line-height: 44px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h1-heading-link:hover {
	color: #aa1e47;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(136, 136, 136, 0.1);
}

.form-label {
	color: #1c3d66;
	margin-bottom: 0.5rem;
	font-weight: 700;
}

.text-field {
	border: 1px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 0.5rem;
	font-style: normal;
	font-weight: 400;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success,
.screen-message.error {
	color: #fff;
	background-color: #fafafa;
	border: 1px solid rgba(91, 91, 91, 0.15);
	border-radius: 5px;
}

.screen-message-text {
	color: #1c3d66;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.screen-message-text.error {
	color: #aa1e47;
}

.div-block-142 {
	flex-direction: row;
	justify-content: space-between;
	display: flex;
}

.cb-wrapper {
	border-radius: 5px;
	flex-direction: column;
	align-items: center;
	width: 29%;
	padding: 0.5rem;
	text-decoration: none;
	transition: box-shadow 0.2s;
	display: flex;
}

.cb-wrapper:hover {
	box-shadow: 0 8px 10px -7px rgba(82, 82, 82, 0.65);
}

.cb-image {
	border-radius: 5px;
	width: auto;
}

.content-image {
	border-radius: 5px;
}

.page-container {
	background-image: url('/20181016082423/assets/images/Logo_icon_grey.svg');
	background-position: 100% 24%;
	background-repeat: no-repeat;
	background-size: 460px;
	flex-flow: column;
	display: flex;
}

.c-button {
	background-color: var(--new-gold);
	color: var(--white);
	text-transform: uppercase;
	border: 1px solid rgba(0, 0, 0, 0);
	width: auto;
	padding: 0.75rem 1rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	transition: border-color 0.2s, color 0.2s, background-color 0.2s;
	display: inline-block;
}

.c-button:hover {
	border-color: var(--gold);
	background-color: var(--white);
	color: var(--new-gold);
}

.c-button.push--down {
	margin-top: auto;
}

.c-button.push--down.is--white {
	background-color: var(--white);
	color: var(--gold);
}

.c-button.push--down.is--white:hover {
	background-color: var(--gold);
	color: var(--white);
}

.c-button.push--down.hidebutton {
	display: none;
}

.about-us {
	grid-column-gap: 3rem;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
}

.about-us.dog-style {
	justify-content: center;
	height: 135px;
	margin-top: 0;
}

.about-us__heading {
	color: var(--gold);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
}

.about-us__heading.blue {
	color: var(--white);
}

.about-us__p {
	margin-bottom: 1rem;
	font-size: 1.2rem;
}

.quick-links__p {
	margin-bottom: 0;
}

.quick-links__heading {
	color: var(--black);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-right: 5px #3eedb6;
	margin-top: 0.5rem;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 1rem;
}

.quick-links {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.quick-links-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: var(--white);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	flex-direction: row;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 0;
	padding: 2rem 4rem 4rem;
}

.otg-cards {
	grid-column-gap: 3rem;
	justify-content: center;
	width: 800px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
}

.otg-cards__img-wrap {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	flex: 0 50%;
	justify-content: center;
	align-items: center;
	max-height: 75vh;
	display: flex;
	overflow: hidden;
}

.otg-cards__text {
	grid-column-gap: 16px;
	grid-row-gap: 2rem;
	text-align: left;
	flex-direction: row;
	flex: 0 35%;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: space-between;
	display: flex;
}

.otg-cards__item {
	grid-column-gap: 2rem;
	justify-content: center;
	width: 300px;
	margin-bottom: 0.5rem;
	display: flex;
}

.otg-cards__item-img {
	flex: none;
	width: 80px;
	padding-top: 0.5rem;
}

.otg-cards__item-text {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.otg-cards__title {
	color: var(--black);
	text-align: center;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-right: 5px #3eedb6;
	margin-top: 0.5rem;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1;
}

.otg-cards__p {
	text-align: center;
}

.otg-cards__link {
	grid-column-gap: 0.5rem;
	background-color: var(--blue);
	color: var(--white);
	text-transform: uppercase;
	border: 1px solid rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: auto;
	padding: 0.75rem 1rem;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	transition: border-color 0.2s, color 0.2s, background-color 0.2s;
	display: flex;
}

.otg-cards__link:hover {
	border-color: var(--blue);
	background-color: var(--white);
	color: var(--blue);
}

.otg-cards__link-text {
	font-size: 0.85rem;
	line-height: 1.5rem;
}

.otg-cards__link-img {
	width: 1.5rem;
	height: 1.5rem;
}

.otg {
	text-align: center;
	width: 100%;
	max-width: 1440px;
	margin: 4rem auto;
}

.otg__title {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
}

.otg__title.fe {
	margin-left: 15px;
}

.otg__title.home-about-title {
	color: #0057a5;
	font-style: italic;
}

.otg__title.home-about-title.title-name,
.otg__title.home-about-title.title-name-copy {
	text-align: right;
	width: 100%;
	font-size: 1rem;
}

.otg__title.left-about {
	color: var(--blue);
}

.otg__title.fe-copy {
	margin-left: 15px;
}

.otg__title.home-about-title-copy {
	color: #0057a5;
	font-style: italic;
}

.otg__title.home-about-title-copy.title-name {
	text-align: right;
	width: 100%;
	font-size: 1rem;
}

.otg__caption.out {
	display: none;
}

.otg-blurb {
	grid-row-gap: 0.5rem;
	flex-direction: column;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.otg-blurb.services,
.otg-blurb.news {
	align-self: auto;
	align-items: flex-start;
	width: auto;
	max-width: 1440px;
	margin-bottom: 40px;
}

.locations-wrapper {
	width: 100%;
	height: 50vh;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
}

.loc__p {
	max-width: 50ch;
	font-size: 1rem;
}

.slideshow__scrolldown-arrow-wrap {
	opacity: 1;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	padding: 0.45rem 0.25rem 0.25rem;
	position: absolute;
	top: -20px;
	bottom: auto;
	left: auto;
	right: auto;
}

.slide__title {
	color: var(--white);
	letter-spacing: 0.05rem;
	text-transform: none;
	max-width: none;
	font-size: 3rem;
	font-weight: 700;
	line-height: 3rem;
}

.slide__title._4thtitle {
	margin-bottom: 10px;
}

.slideshow__nav {
	font-size: 0.8rem;
	position: relative;
	bottom: 40px;
}

.slideshow__arrow-left {
	height: 400px;
	margin-top: 0;
	margin-bottom: 0;
}

.slide__text {
	color: #fff;
	max-width: none;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 1.7rem;
}

.slide__text.col1 {
	width: 50%;
	font-size: 1rem;
}

.slide__text.col1.lastcol {
	width: 400px;
	padding-top: 0;
	padding-bottom: 0;
}

.slideshow__scrolldown-text {
	color: #fff;
	text-align: center;
	font-family: Open Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 600;
}

.slideshow__icon {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	justify-content: center;
	align-items: center;
	width: 2.75rem;
	height: 2.75rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	transition: transform 0.3s, background-color 0.3s;
	display: flex;
}

.slideshow__icon:hover {
	background-color: var(--gold);
	transform: scale(1.035);
}

.slideshow__arrow-right {
	max-width: 1600px;
	height: 400px;
	margin-top: 0;
	margin-bottom: 0;
}

.slideshow__scrolldown {
	z-index: 10000;
	justify-content: center;
	width: 100%;
	height: 50px;
	margin-top: 15px;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 5vh;
	left: 0%;
	right: 0%;
}

.slideshow__scrolldown-arrow {
	color: #fff;
	margin-top: -5px;
	font-size: 0.75rem;
	font-weight: 700;
	line-height: 0.75rem;
	transform: rotate(90deg);
}

.slideshow {
	background-color: rgba(221, 221, 221, 0);
	width: auto;
	height: auto;
}

.slide__contents {
	grid-row-gap: 1rem;
	background-color: rgba(26, 26, 26, 0.45);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	min-width: 25vw;
	max-width: 41vw;
	margin-bottom: 0;
	margin-left: 5vw;
	margin-right: 0;
	padding: 2rem;
	display: block;
	position: static;
	bottom: 7rem;
	left: 8rem;
}

.slide__contents.last {
	grid-column-gap: 16px;
	grid-row-gap: 1rem;
	grid-template-rows: auto auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	width: 800px;
	min-width: auto;
	max-width: 800px;
	display: block;
}

.main-nav__logo-img {
	min-width: 300px;
}

.main-nav__link-underline {
	background-color: #24643d;
	width: 0;
	height: 3px;
	margin-bottom: -1px;
}

.mega-menu__title {
	color: var(--bonus-green);
	margin-bottom: 1.5rem;
	font-weight: 700;
	text-decoration: none;
}

.mega-menu__title:hover {
	color: var(--black);
}

.subnav__button-text {
	text-transform: capitalize;
	align-items: center;
	font-weight: 700;
	display: flex;
}

.subnav {
	z-index: 0;
	grid-column-gap: 1.5rem;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	display: flex;
	position: relative;
}

.subnav__icon {
	min-width: 0.75rem;
	min-height: 0.75rem;
	display: flex;
}

.main-nav__logo-wrapper {
	position: static;
	top: -35px;
}

.mega-menu__link {
	color: #333;
	margin-bottom: 0.55rem;
	font-size: 0.765rem;
	font-weight: 600;
	line-height: 0.95rem;
	text-decoration: none;
}

.mega-menu__link:hover {
	color: var(--bonus-green);
}

.main-nav__link {
	color: var(--blue);
	text-align: center;
	white-space: normal;
	align-items: center;
	height: 100%;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	display: flex;
}

.main-nav__link:hover {
	color: var(--new-gold);
	text-decoration: underline;
}

.main-nav__link.drop-out {
	display: none;
}

.subnav__link {
	opacity: 0.75;
	color: var(--blue);
	font-size: 0.9rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	transition: opacity 0.2s;
}

.subnav__link:hover {
	opacity: 1;
}

.nav-wrapper {
	z-index: 999;
	flex-direction: column;
	width: 100%;
	display: flex;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	bottom: auto;
	left: 0;
	right: 0;
}

.main-nav__link-wrap {
	flex-direction: column;
	justify-content: center;
	align-self: stretch;
	align-items: center;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
	padding: 0 0;
	line-height: 1;
	display: flex;
	position: static;
}

.subnav__button {
	grid-column-gap: 0.5rem;
	border: 1px solid var(--blue);
	background-color: var(--blue);
	color: #fff;
	text-transform: uppercase;
	padding: 0.5rem 0.75rem;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1;
	text-decoration: none;
	transition: background-color 0.3s;
	display: flex;
	position: static;
}

.subnav__button:hover {
	opacity: 1;
	color: #24643d;
	background-color: #fff;
}

.subnav__button.loan,
.subnav__button.out {
	display: none;
}

.main-nav {
	z-index: 5000;
	box-shadow: none;
	background-color: #fff;
	border-bottom: 1px solid #ececec;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
}

.main-nav__menu {
	grid-column-gap: 2rem;
	flex: 0 auto;
	justify-content: flex-end;
	align-items: center;
	max-width: 1440px;
	display: flex;
}

.mega-menu-wrapper {
	background-color: var(--honeydew);
	flex-wrap: wrap;
	width: 100%;
	height: auto;
	transition: height 0.2s;
	overflow: hidden;
}

.mega-menu-wrapper.w--open {
	background-color: #bdd4e7;
	width: 200px;
	max-width: none;
	padding-left: 2rem;
	padding-right: 2rem;
	top: 100%;
	right: 0;
	box-shadow: 0 1px 3px rgba(51, 51, 51, 0.5);
}

.subnav-wrapper {
	background-color: var(--white);
	flex-direction: row;
	margin-bottom: 0;
	padding-bottom: 1rem;
	padding-left: 4rem;
	padding-right: 4rem;
	display: flex;
}

.main-nav__menu-wrapper {
	grid-column-gap: 1rem;
	flex: 1;
	justify-content: space-between;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
}

.main-nav__logo {
	max-width: 300px;
	padding-top: 0.5rem;
}

.slideshow__mask {
	height: 500px;
	margin-top: 0;
}

.social-cta {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: var(--gold);
	flex-direction: row;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	padding: 4rem;
	display: none;
	overflow: hidden;
}

.social-cta__title {
	letter-spacing: 0.5px;
	font-size: 2rem;
	font-weight: 700;
}

.social-cta__p {
	font-size: 1.3rem;
}

.social-cta__social {
	grid-column-gap: 0.5rem;
	background-color: var(--white);
	color: var(--gold);
	align-items: center;
	padding: 0.5rem 0.75rem;
	line-height: 1;
	text-decoration: none;
	transition: color 0.2s, background-color 0.2s;
	display: flex;
}

.social-cta__social:hover {
	background-color: var(--honeydew);
	color: var(--black);
}

.social-cta__icon {
	width: 1.5rem;
	height: 1.5rem;
	line-height: 1;
}

.social-cta__link {
	font-weight: 600;
}

.signoff {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1440px;
	height: 3rem;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.footer-content__logo-link {
	max-width: 350px;
}

.footer-content__logo-img {
	width: 238px;
}

.footer-content__fraud-line {
	border: 1px solid var(--white);
	background-color: var(--blue);
	padding: 0.5rem 0.75rem;
}

.footer-content__fraud-title {
	font-size: 0.9rem;
	font-weight: 700;
}

.footer-content__fraud-number {
	color: var(--white);
	font-size: 1.4rem;
	font-weight: 700;
	text-decoration: none;
}

.c-quick-links {
	grid-row-gap: 0.5rem;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
	display: flex;
}

.c-quick-links.footerlinks {
	grid-row-gap: 0rem;
}

.c-quick-links__link {
	opacity: 0.75;
	color: var(--white);
	font-size: 0.8rem;
	text-decoration: none;
	transition: opacity 0.2s;
	display: inline-block;
}

.c-quick-links__link:hover {
	opacity: 1;
}

.c-social-wrapper {
	grid-column-gap: 1rem;
	justify-content: center;
	display: flex;
}

.c-social__link {
	background-color: var(--white);
	color: var(--gold);
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s;
}

.c-social__link:hover {
	background-color: var(--new-gold);
	color: var(--white);
}

.c-social__link.twitter {
	display: none;
}

.c-social__icon {
	color: var(--new-gold);
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.5rem;
}

.c-social__icon:hover {
	color: var(--white);
}

.c-fdic {
	grid-row-gap: 0.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.c-fdic__text {
	text-align: center;
	font-size: 0.8rem;
}

.signoff-wrapper {
	background-color: var(--blue);
	width: 100%;
	padding-bottom: 1rem;
	padding-left: 2rem;
	padding-right: 2rem;
}

.main-nav__mobile-trigger {
	display: none;
}

.otg-cards__img {
	max-width: none;
	max-height: 100%;
}

.mobile-nav {
	align-self: stretch;
	margin-bottom: 0;
	padding-left: 0;
	line-height: 1;
	display: none;
}

.mobile-nav__link {
	white-space: break-spaces;
}

.mobile-nav__sublist {
	margin-bottom: 0;
	padding-left: 0;
}

.mobile-nav__list {
	align-self: stretch;
	margin-bottom: 0;
	padding-left: 0;
	line-height: 1;
	display: none;
}

.slide-wrapper {
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	width: 100%;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 10%, rgba(255, 255, 255, 0.2) 85%), var(--website-hero-bg-image);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.slide {
	justify-content: space-between;
	align-items: center;
	height: 100%;
	display: flex;
	position: static;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	background-position: 100% 0;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.subnav__link-wrapper,
.subnav__button-wrapper {
	grid-column-gap: 1rem;
	display: flex;
}

.loc__map-embed {
	height: 100%;
	overflow: hidden;
}

.loc__caption {
	grid-row-gap: 0.75rem;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	color: var(--white);
	background-color: rgba(56, 97, 65, 0.75);
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 35vw;
	max-width: 400px;
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-right: 5vw;
	padding: 1rem 2rem 2rem;
	display: none;
	position: absolute;
	top: 0%;
	bottom: 0%;
	right: 0;
}

.loc__title {
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-right: 5px #3eedb6;
	margin-top: 0.5rem;
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 1.4;
}

.body {
	font-family: Roboto, sans-serif;
}

.div-block-143 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.text-block-22 {
	text-align: center;
	font-size: 0.8rem;
	font-weight: 300;
	line-height: 1.7;
}

.footerptext {
	width: 80%;
}

.slide__text2 {
	color: #fff;
	max-width: none;
	margin-bottom: 40px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.slide__text2.col2 {
	width: 50%;
}

.slide__text2.col2.lastcol {
	width: 400px;
}

.slide__text2._4th {
	margin-top: 10px;
	margin-bottom: 20px;
}

.slide__text2.name-tablet {
	margin-right: 0%;
}

.text-span {
	font-size: 1rem;
}

.div-block-144 {
	width: auto;
}

.div-block-145 {
	grid-column-gap: 15px;
	width: 500px;
	display: flex;
}

.tertiarybtnnew {
	background-color: var(--new-gold);
	opacity: 0.85;
	color: #fff;
	margin-top: 5px;
	padding: 0.75rem 1rem;
	font-size: 15px;
	font-weight: 500;
	line-height: 30px;
	text-decoration: none;
	transition: opacity 0.2s ease-in;
	display: flex;
}

.tertiarybtnnew:hover {
	opacity: 1;
}

.tertiarybtnnew.nobg {
	opacity: 1;
	color: var(--blue);
	background-color: rgba(109, 84, 9, 0);
	padding: 0;
}

.tertiarybtnnew.sections {
	width: 200px;
}

.tertiarybtnnew.sectionpromo {
	background-color: rgba(109, 84, 9, 0);
	width: 200px;
	padding-left: 0;
}

.tertiarybtnnew.servicesbtn {
	opacity: 1;
	color: var(--blue);
	background-color: rgba(109, 84, 9, 0);
	margin-top: 2rem;
	padding: 5px 0 0;
}

.ths02-arrow-icon {
	width: 15px;
	margin-top: 0;
	margin-left: 8px;
}

.ths02-arrow-icon._2nd {
	margin-right: 0;
}

.text-block-23 {
	color: var(--white);
	text-transform: uppercase;
	white-space: nowrap;
	margin-top: 0;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 700;
	line-height: 1.5rem;
}

.text-block-23.news,
.text-block-23._2nd {
	color: var(--blue);
}

.titleline {
	background-color: var(--new-gold);
	width: 40px;
	height: 3px;
	margin-bottom: 5px;
}

.titleline.fe {
	margin-left: 15px;
}

.dog1 {
	height: auto;
	position: relative;
	bottom: -1px;
}

.image {
	max-width: 80%;
}

.promo {
	grid-column-gap: 3rem;
	width: 100%;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
}

.promo.dog-style {
	grid-column-gap: 0rem;
	background-color: var(--blue);
	justify-content: center;
	align-items: stretch;
	max-width: none;
	height: 220px;
	margin-top: 0;
	margin-bottom: 64px;
	padding-top: 20px;
}

.about-us__heading-2 {
	color: #386141;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 2.1rem;
	font-weight: 700;
	line-height: 1.2;
}

.c-button-2 {
	color: #fff;
	text-transform: uppercase;
	background-color: #386141;
	border: 1px solid rgba(0, 0, 0, 0);
	padding: 0.75rem 1rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	transition: border-color 0.2s, color 0.2s, background-color 0.2s;
	display: inline-block;
}

.c-button-2:hover {
	color: #386141;
	background-color: #fff;
	border-color: #386141;
}

.slider {
	height: 600px;
	overflow: hidden;
}

.team_content-2 {
	color: #fff;
	background-color: rgba(109, 84, 9, 0.56);
	border-radius: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1.5rem 2rem 1rem;
	position: absolute;
	top: auto;
	bottom: 22%;
	left: 0%;
	right: 25%;
}

.null-arrow {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.team_image-2 {
	object-fit: cover;
	width: 100%;
	height: 28rem;
}

.team_name-copy-2 {
	color: #fff;
	margin-top: 10px;
	font-family: PT Serif, serif;
	font-size: 60px;
	font-weight: 500;
	line-height: 1.5;
	display: none;
}

.text-size-small-copy {
	font-size: 0.875rem;
}

.text-size-small-copy.text-style-muted {
	display: none;
}

.padding-bottom {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.team_item_promo {
	cursor: pointer;
	border-radius: 0;
	width: 50%;
	position: relative;
	overflow: hidden;
}

.text-cta-2 {
	z-index: 1;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}

.team_description-copy {
	color: #fff;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
}

.button-with-arrow-copy {
	opacity: 0.8;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	height: 50px;
	margin-top: 0;
	padding-left: 0;
	padding-right: 19px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-with-arrow-copy:active {
	opacity: 1;
}

.team_description-copy-2 {
	color: #fff;
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 400;
	display: none;
}

.blob-2 {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 99999px;
	width: 40px;
	height: 40px;
	display: block;
	position: absolute;
	top: auto;
	bottom: auto;
	left: 6px;
	right: auto;
}

.heading-11 {
	color: var(--white);
	text-transform: none;
	font-family: Roboto, sans-serif;
	font-size: 35px;
	font-weight: 400;
	line-height: 35px;
}

.team_name-copy {
	color: #fff;
	margin-top: 10px;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.5;
}

.arrow-stem-2 {
	background-color: #fff;
	width: 68px;
	height: 2px;
}

.two-box-promo {
	display: flex;
}

.block-arrow {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 4px;
	justify-content: flex-start;
	align-items: center;
	width: 80px;
	height: 50px;
	margin-left: 10px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.threebox {
	display: flex;
}

.financialeducation {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	border-top: 40px solid var(--gold);
	background-color: rgba(183, 140, 9, 0.05);
	flex-direction: row;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-top: 4rem;
	padding: 4rem 4rem 1rem;
}

.grey-arrow-3 {
	width: 0;
	margin-left: 10px;
}

.div-block-12 {
	justify-content: space-between;
	width: 100%;
	display: flex;
}

.div-block-7 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.image-3 {
	border-radius: 5px;
	width: 120%;
	max-width: none;
}

.image-3.img-zoom {
	width: 100%;
	transition: all 0.45s;
}

.image-3.img-zoom:hover {
	transform: scale(1.1);
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.div-block-5 {
	border-radius: 5px;
	height: 55%;
	overflow: hidden;
}

.text-block-3 {
	color: #374957;
}

.div-block-6 {
	align-items: center;
	width: auto;
	height: 45px;
	margin-bottom: 0;
	display: flex;
	overflow: hidden;
}

.button-3 {
	color: #166b3e;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-weight: 600;
	transition: all 0.45s;
}

.button-3:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
}

.div-block-2 {
	background-color: rgba(7, 105, 58, 0);
	border-radius: 10px;
	flex-direction: column;
	align-items: flex-start;
	width: 30%;
	height: auto;
	padding: 15px;
	display: flex;
}

.heading-2 {
	color: var(--blue);
	text-transform: capitalize;
	margin-top: 0;
	font-size: 17px;
	font-weight: 700;
	line-height: 35px;
}

.titleline-2 {
	background-color: var(--new-gold);
	width: 40px;
	height: 4px;
	margin-top: 30px;
	margin-bottom: 0;
}

.image-4 {
	width: 100%;
	max-width: none;
}

.image-5 {
	min-width: 20px;
	max-width: none;
}

.ths02-nav-link {
	color: #d8dbda;
	align-items: center;
	margin-right: 8px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 15px;
	font-weight: 500;
	line-height: 28px;
	text-decoration: none;
	transition: color 0.2s ease-in;
	display: flex;
	text-wrap: nowrap;
	column-gap: 8px;
}

.ths02-nav-link:hover {
	color: #fcfefe;
}

.ths02-nav-link.pofile {
	margin-left: 10px;
}

.ths02-nav-link.pofile.out {
	display: none;
}

.ths02-profile-icon {
	width: 17px;
	margin-top: -1px;
}

.text-block-24 {
	color: var(--blue);
	font-family: Roboto, sans-serif;
}

.text-block-24:hover {
	text-decoration: underline;
}

.search-field {
	background-image: url('/20181016082423/assets/images/search.svg');
	background-position: 18px;
	background-repeat: no-repeat;
	background-size: auto;
	border: 1px solid #e2e1e6;
	border-radius: 40px;
	height: 40px;
	margin-bottom: 0;
	padding-left: 58px;
	padding-right: 15px;
	font-size: 12px;
	font-weight: 400;
}

.search-field::placeholder {
	color: #a2a2be;
	font-size: 18px;
	font-weight: 400;
}

.search-wrap,
.form {
	font-size: 16px;
}

.hamburger {
	display: none;
}

.form-block {
	margin-bottom: 0;
	font-size: 16px;
}

.uui-navbar07_dropdown-toggle-2-copy {
	color: #475467;
	align-items: center;
	padding: 0.75rem 2rem 0.75rem 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-toggle-2-copy:hover {
	color: var(--gold);
	text-decoration: none;
}

.uui-dropdown-icon-3-copy {
	justify-content: center;
	align-items: center;
	width: 1.25rem;
	height: 1.25rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.uui-dropdown-icon-3-copy:hover {
	color: var(--gold);
}

.uui-navbar07_item-heading-2 {
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
}

.uui-navbar07_item-heading-2:hover {
	color: var(--new-gold);
	text-decoration: underline;
}

.image-33 {
	width: 1.5rem;
	height: 1.5rem;
}

.uui-text-size-small-6 {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
	display: none;
}

.text-block-33 {
	color: var(--blue);
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	text-decoration: none;
}

.text-block-33:hover {
	color: var(--gold);
	text-decoration: underline;
}

.text-block-33.no-line {
	color: #1f2b5e;
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: white;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	text-decoration: none;
}

.text-block-33.no-line:hover {
	text-decoration: none;
}

.uui-navbar07_dropdown-link-list-2-copy {
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: grid;
}

.uui-navbar07_dropdown-link-2 {
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	color: var(--blue);
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-link-2:hover {
	color: var(--gold);
	background-color: #f9fafb;
}

.uui-navbar07_item-right-2 {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar07_icon-wrapper-2 {
	color: #0871b9;
	flex: none;
}

.link-block-9 {
	text-decoration: none;
}

.uui-navbar07_dropdown-list-2-copy {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar07_dropdown-list-2-copy.w--open {
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 0.75rem;
	width: 20rem;
	padding: 0.75rem;
	right: -100%;
}

.uui-navbar07_dropdown-list-2-copy.about.w--open {
	width: 14rem;
	right: -20%;
}

.footer-content__title {
	font-size: 0.9rem;
	font-weight: 700;
}

.cta-wrapper {
	text-align: left;
	flex-direction: column;
	align-items: center;
	margin-top: 0;
	display: flex;
}

.cta-button {
	max-width: 200px;
	margin-bottom: 10px;
	margin-right: 0;
	transition: transform 0.4s;
}

.cta-button:hover {
	max-width: 200px;
	transform: translate(0, -5px);
	box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.5);
}

.download-button:hover {
	pointer-events: auto;
}

.heading-19 {
	color: var(--white);
	margin-bottom: 0;
}

.div-block-146 {
	position: relative;
}

.body-2 {
	font-family: Roboto, sans-serif;
}

.title-container {
	width: 1140px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
}

.text-block-34 {
	color: var(--white);
	padding-top: 45px;
	padding-bottom: 45px;
	padding-left: 15px;
	font-size: 32px;
	font-weight: 700;
}

.pagetitle {
	color: var(--white);
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 45px;
	padding-bottom: 45px;
	font-family: Roboto, sans-serif;
	font-size: 32px;
	line-height: 34px;
}

.pagetitle.inside-top-title {
	padding-left: 15px;
}

.title-line {
	background-color: var(--new-gold);
	width: 40px;
	height: 4px;
}

.left-text {
	width: 50%;
	padding-right: 15px;
}

.textwithimage {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.textwithimage.inside-page {
	width: auto;
	height: auto;
	line-height: 55px;
	display: flex;
}

.image-34 {
	width: 100%;
	max-width: none;
	height: auto;
	max-height: none;
	box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.2);
}

.h3-heading-copy {
	color: #1c3d66;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.h3-heading-copy.section-title {
	background-color: #fafafa;
	margin-top: 0;
	margin-bottom: 0;
	padding: 1rem;
}

.h2-heading-top-line {
	border-top: 0px none var(--gold);
	color: var(--black2);
	margin-bottom: 10px;
	font-family: Roboto, sans-serif;
	font-size: 46px;
	font-weight: 700;
	line-height: 55px;
	text-decoration: none;
}

.h2-heading-top-line.withimage {
	margin-bottom: 20px;
}

.h3-heading-copy-copy {
	color: #1c3d66;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.h3-heading-copy-copy.section-title {
	background-color: #fafafa;
	margin-top: 0;
	margin-bottom: 0;
	padding: 1rem;
}

.h4-heading-copy {
	color: var(--black2);
	text-align: left;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 24px;
}

.list-1 {
	color: var(--off-black);
	align-items: center;
	margin-bottom: 0;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: flex;
}

.list-1.list-item {
	margin-bottom: 1rem;
}

.list-1-icon {
	background-color: var(--blue);
	color: var(--white);
	border-radius: 20px;
	justify-content: center;
	align-self: auto;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-bottom: 0;
	margin-left: 20px;
	margin-right: 8px;
	padding: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: flex;
}

.list-1-icon.list-item {
	margin-bottom: 1rem;
}

.div-block-147 {
	align-items: center;
	display: flex;
}

.list-style-1 {
	align-items: center;
	margin-bottom: 1rem;
	display: flex;
}

.list-style-2-con {
	margin-top: 2rem;
}

.list-2-icon {
	background-color: var(--blue);
	color: var(--white);
	border-radius: 20px;
	justify-content: center;
	align-self: auto;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-bottom: 0;
	margin-left: 20px;
	margin-right: 8px;
	padding: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
	display: flex;
}

.list-2-icon.list-item {
	margin-bottom: 1rem;
}

.div-block-148 {
	background-color: var(--gold);
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-left: 20px;
	margin-right: 8px;
	display: flex;
}

.image-35 {
	width: 18px;
	height: 18px;
}

.list-2-icon {
	background-color: var(--new-gold);
	padding-left: 5px;
}

.list-style2 {
	align-items: center;
	margin-bottom: 1rem;
	display: flex;
}

.list-style-3-con {
	margin-top: 2rem;
}

.list-style3 {
	align-items: center;
	margin-bottom: 1rem;
	display: flex;
}

.list-3-icon {
	background-color: var(--new-gold);
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	margin-left: 20px;
	margin-right: 8px;
	padding: 5px 5px 5px 7px;
	display: flex;
}

.table-style-1-con {
	padding-bottom: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.table-style-1-title {
	background-color: var(--blue);
}

.paragraph-2 {
	color: var(--white);
}

.paragraph-3 {
	color: var(--white);
	margin-bottom: 0;
	padding: 15px;
	font-size: 28px;
}

.text-list {
	border-bottom: 1px solid #e4e4e4;
	justify-content: space-between;
	width: auto;
	margin: 20px 10px 10px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.text-list.last {
	border-bottom-width: 0;
}

.text-block-35 {
	color: var(--off-black);
	justify-content: flex-end;
	padding-left: 10px;
	font-weight: 400;
	display: flex;
}

.text-block-left {
	color: var(--off-black);
	justify-content: flex-end;
	padding-bottom: 5px;
	padding-left: 10px;
	font-weight: 400;
	display: flex;
}

.text-block-right {
	color: var(--off-black);
	justify-content: flex-end;
	padding-left: 0;
	padding-right: 10px;
	font-weight: 400;
	display: flex;
}

.page-title-main {
	background-color: #00549d;
	height: auto;
	margin-top: 0;
}

.page-title-main.travel-with-debit-card {
	background-image: url('/20181016082423/assets/images/travel-debit-card-banner-image.jpg');
	background-position: 50% 30%;
}

.inside-content {
	background-color: #fff;
	border-top: 1px #000;
	max-width: 1140px;
	margin-top: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0 0 3rem;
	display: flex;
	position: static;
}

.main-content-section-2 {
	border-top: 0 solid rgba(91, 91, 91, 0.15);
	max-width: none;
	padding-top: 1rem;
	font-family: Roboto, sans-serif;
}

.link-3 {
	border-bottom: 1.5px solid var(--blue);
	color: var(--blue);
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 1.5rem;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	text-decoration: none;
}

.link-3:hover {
	border-bottom-style: none;
	text-decoration: none;
}

.tertiary-button-new {
	border-bottom: 1.5px solid var(--blue);
	color: var(--blue);
	margin-top: 2rem;
	margin-bottom: 0;
	margin-left: 1.5rem;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	text-decoration: none;
}

.tertiary-button-new:hover {
	border-bottom-style: none;
	text-decoration: none;
}

.secondary-button-2 {
	color: #000;
	align-items: center;
	margin-left: 25px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-decoration: none;
	transition: all 0.2s;
	display: flex;
}

.secondary-button-2:hover {
	opacity: 0.8;
	transform: translate(5px);
}

.secondary-button-2.no-left-margin {
	margin-top: 20px;
	margin-left: 0;
}

.right-arrow {
	color: var(--blue);
	height: 15px;
	margin-top: 2px;
	margin-left: 7px;
}

.text-block-36 {
	color: var(--blue);
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
}

.text-block-36:hover {
	color: var(--blue);
}

.tertiary-button-2 {
	color: #000;
	align-items: center;
	margin-left: 25px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-decoration: none;
	transition: all 0.2s;
	display: flex;
}

.tertiary-button-2:hover {
	opacity: 0.8;
	transform: translate(5px);
}

.tertiary-button-2.no-left-margin {
	margin-top: 2rem;
	margin-left: 0;
	display: block;
}

.tertiary-button-2.no-left-margin:hover {
	opacity: 1;
}

.tertiary-button-3 {
	background-color: var(--dark-gold);
	opacity: 0.85;
	color: #fcfefe;
	margin-top: 5px;
	padding: 0.75rem 1rem;
	font-size: 15px;
	font-weight: 500;
	line-height: 30px;
	text-decoration: none;
	transition: opacity 0.2s ease-in;
	display: flex;
}

.tertiary-button-3:hover {
	opacity: 1;
}

.tertiary-button-3.nobg {
	color: var(--blue);
	background-color: rgba(109, 84, 9, 0);
	padding: 0;
}

.tertiary-button-3.sections {
	width: 200px;
}

.tertiary-button-3.sectionpromo {
	background-color: rgba(109, 84, 9, 0);
	width: 200px;
	padding-left: 0;
}

.tertiary-button-3.servicesbtn {
	opacity: 1;
	color: var(--blue);
	background-color: rgba(109, 84, 9, 0);
	margin-top: 20px;
	padding: 0.9rem 0 0;
	display: none;
}

.left-text-image {
	width: 100%;
	padding-right: 15px;
}

.div-block-149 {
	flex-flow: column;
	width: 50%;
	display: flex;
}

.div-block-150 {
	flex-flow: row;
	width: 100%;
	display: flex;
}

.div-block-149-copy {
	flex-flow: column;
	width: 50%;
	display: flex;
}

.left-side-col {
	float: left;
	background-color: #fef8e0;
	flex: none;
	width: 25%;
	height: 100.002%;
	margin-left: 2%;
	padding: 20px 50px 20px 25px;
	position: relative;
	right: 0;
}

.left-side-col.show {
	color: #000;
	background-color: #fafafa;
	border-radius: 5px;
	width: 100%;
	margin-left: 0%;
	padding-left: 20px;
	padding-right: 20px;
	right: auto;
}

.right-col-item-wrap {
	border-top: 3px #d5a021;
	margin-top: 0;
	margin-left: 0;
	position: relative;
}

.heading-14 {
	color: #8b1518;
	margin-top: 10px;
}

.heading-14.black {
	color: var(--black);
	margin-top: 0;
	font-family: Roboto, sans-serif;
}

.header-link {
	color: #fff;
	border: 1px #000;
	text-decoration: underline;
}

.header-link:hover {
	color: #8b1518;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
	transform: translate(46px);
}

.paragraph-9 {
	color: #120522;
}

.paragraph-9.black {
	margin-bottom: 0;
	font-size: 16px;
	line-height: 26px;
}

.pagetitlemain {
	background-color: var(--blue);
	height: auto;
	margin-top: 0;
}

.pagetitlemain.travel-with-debit-card {
	background-image: url('/20181016082423/assets/images/travel-debit-card-banner-image.jpg');
	background-position: 50% 30%;
}

.header-right-upper-wrap {
	border-bottom: 0 solid rgba(0, 0, 0, 0.1);
	justify-content: flex-end;
	align-items: stretch;
	width: 100%;
	height: auto;
	padding-top: 0;
	display: flex;
	position: static;
}

.header-content-wrap {
	justify-content: space-between;
	width: auto;
	margin-left: 2vw;
	margin-right: 2vw;
	display: flex;
}

.nav-bar-widget-2 {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	margin-top: 10px;
	margin-left: 0;
	margin-right: 1vw;
	display: flex;
}

.ths01-search-input {
	background-color: rgba(245, 245, 245, 0);
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 320px;
	height: 50px;
	margin-bottom: 0;
	line-height: 20px;
}

.ths01-search-input:active {
	border-color: #678338;
}

.ths01-search-input:focus {
	border-color: #3c4a20;
}

.ths01-search-input::placeholder {
	color: #a0989b;
}

.social-media {
	justify-content: flex-end;
	display: none;
}

.main-nav__logo-img-2 {
	min-width: 450px;
}

.text-block-44 {
	margin-left: 15px;
	font-size: 13px;
}

.text-block-44:hover {
	color: #3c4a20;
}

.text-block-44:active {
	color: #3c4a20;
	-webkit-text-stroke-color: #3c4a20;
}

.text-block-58 {
	color: #00549d;
	font-family: Roboto, sans-serif;
}

.text-block-58:hover {
	text-decoration: underline;
}

.main-nav__logo-2 {
	max-width: 450px;
	margin-top: 10px;
	padding-top: 0.5rem;
}

.ths01-search-button {
	background-color: #0057a5;
	background-image: url('/20181016082423/assets/images/2ths_01_ico_search.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px 25px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 0;
	transition: background-color 0.25s ease-in-out;
	position: absolute;
	top: 7.5px;
	bottom: auto;
	left: auto;
	right: 7.5px;
}

.ths01-search-button:hover {
	background-color: var(--new-gold);
}

.subnav__link-wrapper-2 {
	grid-column-gap: 1rem;
	display: flex;
}

.uui-navbar07_dropdown-link-list-2-copy-2 {
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: grid;
}

.subnav-search-wrap {
	float: right;
	background-color: rgba(0, 0, 0, 0);
	justify-content: space-between;
	align-self: flex-end;
	align-items: center;
	width: auto;
	height: 50px;
	display: flex;
}

.uui-navbar07_dropdown-link-3 {
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	color: #00549d;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-link-3:hover {
	color: var(--gold);
	background-color: #f9fafb;
}

.main-nav__link-2 {
	color: #00549d;
	text-align: center;
	white-space: normal;
	align-items: center;
	height: 100%;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	display: flex;
}

.main-nav__link-2:hover {
	color: var(--new-gold);
	text-decoration: underline;
}

.uui-navbar07_dropdown-toggle-2-copy-2 {
	color: #475467;
	align-items: center;
	padding: 0.75rem 2rem 0.75rem 1rem;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-toggle-2-copy-2:hover {
	color: #b68c09;
	text-decoration: none;
}

.main-nav-link-3 {
	color: #000;
	cursor: pointer;
	padding: 0.5rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.15s;
	display: block;
	position: relative;
}

.main-nav-link-3.w--current {
	color: #f9b604;
	background-color: rgba(3, 52, 102, 0.6);
}

.text-block-57 {
	color: #00549d;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	text-decoration: none;
}

.text-block-57:hover {
	color: var(--new-gold);
	text-decoration: underline;
}

.uui-navbar07_item-heading-3 {
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 700;
}

.uui-navbar07_item-heading-3:hover {
	color: #946f05;
	text-decoration: underline;
}

.topbar-container {
	justify-content: flex-end;
	align-items: center;
	width: auto;
	max-width: none;
	margin-top: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
}

.header-right-wrap {
	flex-direction: column;
	flex: 0 auto;
	justify-content: center;
	align-items: flex-end;
	width: auto;
	display: flex;
	position: relative;
}

.text-block-43 {
	margin-left: 15px;
	font-size: 13px;
}

.text-block-43:hover {
	color: #3c4a20;
}

.upper-left-wrap {
	text-transform: uppercase;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	height: 100%;
	display: flex;
	position: static;
}

.section-9 {
	z-index: 5000;
	border-top: 10px solid var(--new-gold);
	background-color: #fff;
	border-bottom: 1px solid #ececec;
	margin-top: 0;
	padding-bottom: 10px;
	position: static;
	top: 0;
}

.subnav-wrap {
	flex: none;
	justify-content: center;
	align-items: center;
	margin-right: 2vw;
	display: flex;
}

.ths01-search {
	flex-direction: row;
	justify-content: flex-end;
	width: 220px;
	margin-bottom: 0;
	margin-left: 40px;
	display: flex;
	position: relative;
	right: 0;
}

.social-list {
	padding-left: 10px;
	padding-right: 10px;
}

.logo-wrapper-2 {
	width: auto;
	position: relative;
}

.main-nav__link-wrap-2 {
	flex-direction: column;
	justify-content: center;
	align-self: stretch;
	align-items: center;
	height: 100%;
	margin: 0.5rem 10px 0.4rem;
	padding: 0.75rem 10px 0.75rem 0;
	line-height: 1;
	display: flex;
	position: relative;
}

.main-nav__link-wrap-2.firsr-menu-btn {
	margin-left: 20px;
}

.main-nav__link-wrap-2.lastbtn,
.main-nav__link-wrap-2.last {
	margin-right: 0;
}

.contact-item {
	color: #4a494b;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 15px;
	font-weight: 500;
	line-height: 22.5px;
	text-decoration: none;
	display: flex;
}

.contact-item:hover {
	color: #3c4a20;
}

.contact_data {
	align-items: center;
	display: none;
}

.sliderdiv {
	height: 500px;
	margin-top: 0;
}

.sliderdiv.hide-slide-mob {
	border-bottom: 0px solid var(--gold);
}

.nav-link {
	color: #000;
	cursor: pointer;
	padding: 0.5rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.15s;
	display: block;
	position: relative;
}

.nav-link.w--current {
	color: #f9b604;
	background-color: rgba(3, 52, 102, 0.6);
}

.nav-link.mobile {
	display: none;
}

.header-nav-inner-content {
	background-color: #fff;
	border-bottom: 1px solid rgba(110, 110, 110, 0.15);
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
	height: auto;
	padding: 3.5rem 2% 0.5rem;
	display: flex;
}

.alert-box-close-button {
	z-index: 500;
	float: right;
	color: rgba(255, 255, 255, 0.8);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	cursor: pointer;
	background-image: url('/20181016082423/assets/images/exit-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	border-bottom-style: none;
	width: 25px;
	height: 25px;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0%;
	padding-left: 0;
	padding-right: 15px;
	font-size: 0.75rem;
	line-height: 1.5em;
	text-decoration: none;
	transition: all 0.3s;
	position: absolute;
	top: auto;
	right: 3rem;
}

.alert-box-close-button:hover {
	color: #fff;
}

.nav-dropdown-link {
	color: #fff;
	letter-spacing: 0.75px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
	font-size: 0.9rem;
	font-weight: 400;
}

.nav-dropdown-link:hover {
	background-color: #6e2216;
}

.secondary-nav-icon {
	float: left;
	background-color: rgba(91, 99, 107, 0);
	width: auto;
	height: 2.5rem;
	margin-left: 0;
	margin-right: 0;
	padding: 6px;
}

.header-nav-wrappper {
	height: auto;
	padding-left: 0;
	padding-right: 0;
}

.search-input-exit {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	width: 5%;
	display: block;
}

.search-input-exit.two {
	display: none;
}

.navbar-wrapper {
	flex-direction: column;
	display: flex;
}

.div-block-101 {
	background-color: #044f5f;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0.5rem;
	padding-left: 10px;
	display: flex;
}

.top-header-wrapper {
	cursor: pointer;
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	align-items: center;
	margin-left: 1rem;
	display: flex;
}

.top-header-wrapper.not {
	display: none;
}

.navbar {
	background-color: #fff;
	padding-top: 20px;
	position: fixed;
	top: 0%;
	left: 0%;
	right: 0%;
}

.search {
	justify-content: flex-end;
	width: 250px;
	margin-bottom: 0;
	display: flex;
}

.button {
	color: #fff;
	letter-spacing: 0.5px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #6e2216;
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	border-radius: 0;
	padding: 0;
	font-size: 0.9rem;
	line-height: 1rem;
	transition: border-color 0.35s;
}

.button:hover {
	border-bottom-color: #fafafa;
}

.icon-4 {
	color: #002569;
	padding-left: 5px;
	font-size: 0.7rem;
	display: none;
}

.secondary-nav-icon-copy {
	float: left;
	background-color: rgba(91, 99, 107, 0);
	border-left: 1px solid #fff;
	width: auto;
	height: 2.5rem;
	margin-left: 0;
	margin-right: 0;
	padding: 6px 6px 8px 20px;
	line-height: 0;
}

.dropdown-icon {
	background-image: url('/20181016082423/assets/images/navigation-blue-arrow-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto;
	width: 10px;
	height: 10px;
	margin-left: 0.5rem;
	padding-left: 0;
}

.dropdown-icon.translate {
	background-image: url('/20181016082423/assets/images/navigation-white-arrow-icon.svg');
}

.nav-link-2 {
	color: #002d44;
	text-transform: uppercase;
	align-items: center;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
	padding: 0 1.35rem;
	font-size: 16px;
	font-weight: 700;
	line-height: 1rem;
	display: flex;
}

.nav-link-2:hover {
	color: #6e2216;
	font-weight: 700;
}

.nav-link-2.last {
	padding-right: 0;
}

.brand-image {
	width: 400px;
	max-width: 400px;
}

.nav-menu {
	flex-wrap: wrap;
	justify-content: flex-start;
	align-self: stretch;
	align-items: stretch;
	height: 80px;
	margin-left: 0;
	display: flex;
}

.search-bar-wrap {
	align-items: center;
	margin-bottom: 0;
	display: flex;
}

.dropdown-toggle {
	color: #fff;
	text-transform: uppercase;
	align-items: center;
	height: 100%;
	padding: 1.4rem 1.35rem 0.25rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1rem;
	display: flex;
}

.top-header-nav-wrapper {
	background-color: rgba(23, 108, 113, 0);
	justify-content: flex-end;
	align-items: center;
	padding: 0 2%;
	display: flex;
}

.search-input-form-2 {
	color: #242424;
	letter-spacing: 0.5px;
	border: 1px solid rgba(36, 36, 36, 0.1);
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	width: 250px;
	height: 30px;
	margin-bottom: 0;
	font-size: 0.7rem;
	font-weight: 500;
}

.dropdown {
	margin-left: 0;
	margin-right: 0;
	display: none;
}

.text-block-4 {
	color: #002d44;
	letter-spacing: 0.5px;
	text-transform: none;
	font-size: 16px;
	font-weight: 700;
}

.text-block-4:hover {
	color: #6e2216;
}

.search-input {
	border: 1px #000;
	width: 95%;
	margin-bottom: 0;
	padding: 2rem 1.25rem;
	font-size: 0.85rem;
	font-weight: 300;
}

.alert-header-section-wrapper {
	background-color: #092c56;
	display: none;
	overflow: hidden;
}

.brand {
	margin-top: 0;
	margin-left: 40px;
}

.alert-header-text {
	flex-grow: 1;
	flex-shrink: 1;
	align-self: center;
	align-items: center;
	display: flex;
	position: relative;
}

.search-button-2 {
	background-color: #1e6295;
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 45%;
	border-radius: 20px;
	width: 30px;
	height: 30px;
	margin-left: -14px;
	font-size: 12px;
	line-height: 15px;
	transition: all 0.2s;
}

.search-button-2:hover {
	background-color: #fcb31c;
}

.aleart-header {
	color: #fff;
	justify-content: space-around;
	align-items: center;
	padding-top: 0;
	display: block;
	position: relative;
}

.alert-header-content-wrapper {
	padding: 0.75rem 0;
	font-size: 0.875em;
	display: flex;
	position: relative;
}

.alert-header-inner-text {
	color: #fff;
	letter-spacing: 0.5px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 1rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.search-bar {
	z-index: 0;
	background-color: #fff;
	justify-content: space-between;
	align-items: center;
	display: none;
	position: relative;
	top: 0;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.dropdown-list {
	background-color: #fff;
}

.dropdown-list.w--open {
	background-color: #002d44;
	margin-top: 0;
	padding-top: 0;
	top: 63px;
}

.search-section {
	justify-content: flex-end;
	align-items: flex-start;
	width: auto;
	display: none;
}

.alert-header-container {
	justify-content: center;
	align-items: center;
	max-width: none;
	padding-left: 3rem;
	padding-right: 3rem;
	display: flex;
	position: relative;
}

.top-header-nav-link {
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: none;
	border-bottom: 1px solid rgba(36, 36, 36, 0);
	margin-left: 1rem;
	margin-right: 1rem;
	padding: 0;
	font-size: 0.825rem;
	font-weight: 400;
	line-height: 0.85rem;
	transition: border-color 0.35s, color 0.35s;
}

.top-header-nav-link:hover {
	color: #fff;
	border-bottom: 1px solid #fff;
}

.top-header-nav-link.not {
	display: none;
}

.top-header-nav-link.translate {
	border-bottom-style: none;
	margin-right: 0;
	padding-right: 0;
}

.top-header-nav-link.translate:hover {
	border-bottom-style: none;
}

.top-header-nav-link.last {
	margin-left: 0.25rem;
	margin-right: 1.3rem;
	padding-right: 0;
}

.div-block-151 {
	height: 2000px;
}

.div-block-152 {
	background-color: #044f5f;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0.5rem;
	padding-left: 10px;
	display: flex;
}

.button-4 {
	color: #fff;
	letter-spacing: 0.5px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #6e2216;
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
	border-radius: 0;
	padding: 0;
	font-size: 0.9rem;
	line-height: 1rem;
	transition: border-color 0.35s;
}

.button-4:hover {
	border-bottom-color: #fafafa;
}

.text-block-59 {
	color: #002d44;
	letter-spacing: 0.5px;
	text-transform: none;
	font-size: 16px;
	font-weight: 700;
}

.text-block-59:hover {
	color: #6e2216;
}

.topborder {
	z-index: 3000;
	background-color: var(--new-gold);
	height: 10px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.div-block-153 {
	align-self: flex-end;
	width: 100%;
}

.top {
	width: 100%;
}

.top-2 {
	width: 100%;
	height: 0;
	padding-bottom: 0;
	display: block;
}

.nav-search {
	display: none;
}

.nav-dropdown__items {
	background-color: rgba(1, 110, 81, 0.85);
}

.nav-dropdown__items.w--open {
	padding: 0.5rem;
}

.uui-navbar07_dropdown-link-list-2-copy-3 {
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: grid;
}

.subnav-wrap-2 {
	flex: none;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.uui-dropdown-icon-3-copy-2 {
	justify-content: center;
	align-items: center;
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 10px;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.uui-dropdown-icon-3-copy-2:hover {
	color: #946f05;
}

.nav-links-wrapper {
	display: none;
}

.subnav-search-wrap-2 {
	float: right;
	background-color: rgba(0, 0, 0, 0);
	justify-content: space-between;
	align-self: flex-end;
	align-items: center;
	width: auto;
	height: auto;
	display: flex;
}

.nav-head__logo-img {
	max-width: 350px;
	width: 100%;
	height: 100%;
	margin-top: 10px;
	transition: opacity 0.2s;
}

.uui-navbar07_dropdown-toggle-2-copy-3 {
	color: #475467;
	align-items: center;
	padding: 0.75rem 2rem 0.75rem 1rem;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-toggle-2-copy-3:hover {
	color: #b68c09;
	text-decoration: none;
}

.nav-dropdown__toggle {
	padding: 0;
}

.nav-head__logo {
	perspective-origin: 0%;
	transform-origin: 0%;
}

.ths01-search-button-2 {
	background-color: #0057a5;
	background-image: url('/20181016082423/assets/images/2ths_01_ico_search.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px 25px;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 0;
	transition: background-color 0.25s ease-in-out;
	position: absolute;
	top: 7.5px;
	bottom: auto;
	left: auto;
	right: 7.5px;
	font-size: 0px;
}

.ths01-search-button-2:hover {
	background-color: #946f05;
}

.nav-links {
	align-items: center;
	display: flex;
}

.text-block-60 {
	color: #00549d;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	text-decoration: none;
}

.text-block-60:hover {
	color: #946f05;
	text-decoration: underline;
}

.text-block-61 {
	color: #00549d;
}

.text-block-61:hover {
	text-decoration: underline;
}

.text-block-61.hide {
	font-family: Roboto, sans-serif;
}

.nav-dropdown__item {
	color: #fff;
}

.nav-dropdown__item:hover {
	color: #fff;
	background-color: rgba(1, 110, 81, 0.85);
}

.text-block-62 {
	margin-left: 15px;
	font-size: 13px;
}

.text-block-62:hover {
	color: #3c4a20;
}

.nav-search__img {
	height: 25px;
}

.nav-links__arrow {
	display: none;
}

.nav-links__item {
	color: #016e51;
	text-transform: uppercase;
	padding: 0.5rem 1.75rem;
	font-size: 1.1rem;
	line-height: 1;
	transition: box-shadow 0.2s;
}

.nav-links__item:hover {
	box-shadow: inset 0 -2px #016e51;
}

.nav-links__item.is--hidden {
	display: none;
}

.uui-navbar07_item-heading-4 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
}

.uui-navbar07_item-heading-4:hover {
	color: #946f05;
	text-decoration: underline;
}

.text-block-63 {
	margin-left: 15px;
	font-size: 13px;
}

.text-block-63:hover {
	color: #3c4a20;
}

.text-block-63:active {
	color: #3c4a20;
	-webkit-text-stroke-color: #3c4a20;
}

.nav-head__container {
	background-color: rgba(0, 0, 0, 0);
	justify-content: space-between;
	align-items: center;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
	display: flex;
}

.uui-navbar07_dropdown-link-4 {
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	color: #00549d;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar07_dropdown-link-4:hover {
	color: #946f05;
	background-color: #f9fafb;
}

.nav-head {
	z-index: 9999;
	border-top: 10px solid var(--new-gold);
	border-bottom: 0px solid var(--new-gold);
	object-fit: fill;
	background-color: #fff;
	width: 100%;
	height: auto;
	margin-bottom: 0;
	padding-left: 4vw;
	padding-right: 4vw;
	display: block;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0%;
	right: 0%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.main-nav__link-3 {
	color: #00549d;
	text-align: center;
	white-space: normal;
	align-items: center;
	height: 100%;
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	display: flex;
}

.main-nav__link-3:hover {
	color: #946f05;
	text-decoration: underline;
}

.ourpeople {
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	display: flex;
}

.ourpeople.hide-style {
	display: none;
}

.peoplecon {
	background-image: var(--website-hero-bg-image);
	background-position: 50%;
	background-size: auto;
	justify-content: center;
	align-items: flex-end;
	width: 400px;
	height: 250px;
	display: flex;
	box-shadow: 1px 1px 6px 2px rgba(56, 76, 97, 0.6);
}

.imagediv {
	height: 250px;
}

.namediv {
	align-items: flex-end;
	height: auto;
	margin-right: 0%;
	display: flex;
}

.image-36,
.image-1st,
.image-2nd,
.image-3rd {
	height: 250px;
}

.slider-img {
	align-items: flex-end;
	height: 100%;
	display: flex;
	width: 450px;
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: 100% auto;
}

.slider-promo {
	align-items: flex-end;
	height: 100%;
	margin-left: 0;
	margin-right: 10%;
	display: flex;
}

.image-37 {
	height: 100%;
}

.helpbutton {
	border-radius: 0;
	margin-left: 20px;
}

.helpcon {
	border: 1px solid var(--new-gold);
	color: #475467;
	background-color: #fff;
	border-radius: 4px;
	align-items: center;
	padding: 0.75rem 3.5rem 0.75rem 1rem;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
	line-height: 1.3rem;
}

.helpcon:hover {
	color: #b68c09;
	text-decoration: none;
}

.helpmain {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.helpmain.w--open {
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 0.75rem;
	width: 20rem;
	padding: 0.75rem;
	right: -100%;
}

.helpmain.about.w--open {
	width: 14rem;
	right: -20%;
}

.helpmain.abouthelp.w--open {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	width: 16rem;
	left: 0;
	right: auto;
}

.help_dropdown {
	grid-column-gap: 0px;
	grid-row-gap: 0.5rem;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: grid;
}

.help-dropdown_link {
	grid-column-gap: 1rem;
	grid-row-gap: 0px;
	color: #00549d;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-start;
	align-items: center;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.help-dropdown_link:hover {
	color: #946f05;
	background-color: #f9fafb;
}

.divider-gold {
	background-color: var(--gold);
	height: 40px;
}

.divider-gold._1st {
	border-bottom: 5px solid var(--white);
}

.div-block-155 {
	display: flex;
}

.titleline-copy {
	background-color: var(--new-gold);
	width: 40px;
	height: 3px;
	margin-bottom: 5px;
}

.titleline-copy.fe {
	margin-left: 15px;
}

.about-us__p-copy {
	margin-bottom: 1rem;
	font-size: 1.2rem;
}

.otg-rates {
	text-align: center;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto 4rem;
}

.topbar-container-mobile {
	justify-content: flex-end;
	align-items: center;
	width: auto;
	max-width: none;
	margin-top: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
}

.topbar-container-mobile.mobileonly {
	display: none;
}

.subnav__link-wrapper-2-mobile {
	grid-column-gap: 1rem;
	display: flex;
}

.mobile-search {
	opacity: 1;
	object-fit: fill;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	display: flex;
	overflow: visible;
}

.search-input-2 {
	border-radius: 20px;
	height: 38px;
	margin-bottom: 0;
	margin-right: -38px;
}

.search-button {
	background-color: #b6b6b6;
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 20px;
	width: 38px;
	height: 38px;
	margin-left: 0;
	margin-right: 0;
	transition: all 0.45s;
}

.search-button:hover {
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-repeat: no-repeat;
	background-size: 15px;
	border: 2px solid #000;
}

.searchicon {
	margin-top: 5px;
	margin-left: 40px;
	margin-right: 30px;
	display: inline-block;
}

.searchicon.mobile {
	background-color: #11357a;
	border-radius: 20px;
	margin-top: -5px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 5px;
	display: block;
	position: relative;
}

.search-cb-wrapper {
	position: relative;
	line-height: 10px;
}

.search-button-icon {
	z-index: 2;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 10px;
	height: 30px;
	margin-top: 0;
	position: relative;
}

.search-button-icon:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-button-icon:active {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.exit-cb-wrapper {
	position: relative;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.exit-button-icon {
	z-index: 2;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon-white_1.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 10px;
	height: 30px;
	margin-top: 0;
	position: relative;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.exit-button-icon:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/exit-icon-white_1.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.exit-button-icon:active {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-content {
	z-index: 5;
	opacity: 1;
	background-color: #fff;
	border-top: 1px rgba(91, 91, 91, 0.15);
	border-radius: 0;
	justify-content: space-around;
	align-items: center;
	width: 100vw;
	height: 80px;
	margin: 100px 0 0;
	padding: 0 5rem;
	display: none;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
	box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.1);
}

.search-2 {
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	display: flex;
}

.search-input-3 {
	opacity: 1;
	color: #fff;
	letter-spacing: 0.5px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 0.9rem;
}

.search-button-3 {
	color: #444545;
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	border: 2px #b08d57;
	border-radius: 100px;
	padding: 0;
	font-size: 0.9rem;
	line-height: 1rem;
	transition: color 0.2s;
}

.search-button-3:hover {
	color: #b08d57;
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
}

@media screen and (min-width: 992px) {
	.ths02-nav-link.pofile {
		margin-right: 0px;
	}
}

@media screen and (max-width: 991px) {
	h2 {
		font-size: 1.75rem;
	}

	.navbar__logo-link {
		max-width: 275px;
	}

	.primary-button {
		justify-content: flex-start;
		align-items: center;
		height: auto;
		margin-top: 1.5rem;
		display: flex;
	}

	.quick-links__card {
		padding: 1rem;
	}

	.about-us__text {
		text-align: left;
		flex-direction: column;
		flex-basis: auto;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		margin-bottom: 0;
		padding-left: 20px;
		display: flex;
		position: relative;
		bottom: 0;
	}

	.about-us__text.mobile {
		display: none;
	}

	.about-us__text.mobile-view {
		padding-left: 0;
	}

	.footer-content {
		grid-row-gap: 2rem;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-flow: row;
	}

	.footer-content.signoff-container {
		flex-flow: wrap;
		justify-content: space-between;
		padding-left: 3rem;
		padding-right: 3rem;
		display: flex;
	}

	.footer-left-content-wrapper {
		border-bottom: 1px rgba(255, 248, 225, 0.25);
		justify-content: space-around;
		align-items: center;
		width: 100%;
		padding-bottom: 1rem;
	}

	.footer-right-content-wrap {
		justify-content: space-between;
		width: 100%;
		padding-top: 1rem;
	}

	.footer-signoff-link,
	.footer-signoff-link.first-signoff-link {
		opacity: 1;
	}

	.footer-nav-item {
		text-align: left;
		margin-bottom: 0.5rem;
	}

	.cb-heading.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.footer-content__item.footer-cb-left {
		justify-content: flex-start;
		align-items: flex-start;
		width: 25%;
		margin-right: 2rem;
	}

	.footer-content__item.footer-cb-left.last-footer-cb {
		margin-right: 0;
	}

	.footer-content__item.footer-logo-left {
		justify-content: center;
		align-items: center;
		width: 50%;
	}

	.footer-content__item.is--centered {
		padding-left: 40px;
	}

	.footer-nav-list {
		margin-bottom: 0;
		padding-left: 0;
	}

	.footer-icon-block {
		flex: none;
	}

	.equal-housing-lender-img {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 1rem;
	}

	.certifications-icons {
		justify-content: flex-start;
		margin-bottom: 0;
	}

	.member-fdic-img {
		margin-left: 10px;
		margin-right: 10px;
	}

	.resources-list-item {
		width: 48%;
		margin-bottom: 1.5rem;
	}

	.image-banner {
		background-image: url('/20181016082423/assets/images/AdobeStock_446315472-copy.jpg');
		background-position: 50% 30%;
		background-repeat: no-repeat;
		background-size: cover;
		height: 250px;
	}

	.image-banner.travel-with-debit-card {
		background-position: 50% 0;
	}

	.main-content-column {
		padding-left: 0;
		padding-right: 0;
	}

	.breadcrumb-list {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0.8rem;
	}

	.breadcrumb-list.inside-left-3 {
		padding-left: 0;
	}

	.div-block-140 {
		width: 65%;
	}

	.inside-content-background {
		max-width: 100%;
		padding: 1rem 3rem 3rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.content-container,
	.content-container.main-content {
		width: 100%;
		padding-left: 0;
	}

	.left-nav-list-content-wrap {
		height: 0;
		overflow: hidden;
	}

	.main-content-wrapper {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.inside-page-header-wrap {
		max-width: none;
		margin-bottom: 0;
		padding-bottom: 0;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.inside-page-header-wrap.inside-right,
	.inside-page-header-wrap.inside-left-2 {
		padding-left: 0;
		padding-top: 8px;
	}

	.secondary-button,
	.tertiary-button {
		justify-content: flex-start;
		align-items: center;
		height: auto;
		margin-top: 1.5rem;
		display: flex;
	}

	.right-col {
		border: 1px #000;
		padding-left: 0;
		padding-right: 0;
	}

	.right-sidebar {
		border-top: 0 solid rgba(91, 91, 91, 0.15);
		border-left-style: solid;
		width: 100%;
		margin-top: 2rem;
		margin-left: 1rem;
		padding-top: 2rem;
		padding-left: 1rem;
	}

	.right-sidebar.tavlet {
		margin-left: 0;
	}

	.cb-wrapper {
		width: 33%;
	}

	.page-container {
		background-position: 140% 20%;
		background-size: 400px;
		margin-top: 0;
	}

	.c-button {
		line-height: 1.1;
	}

	.about-us {
		grid-row-gap: 2rem;
		flex-direction: column;
	}

	.about-us__heading.blue {
		align-self: flex-start;
		padding-left: 20px;
		font-size: 2.2rem;
	}

	.about-us__p {
		text-align: left;
		margin-top: 60px;
	}

	.quick-links__heading {
		line-height: 1.2;
	}

	.quick-links {
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.quick-links-wrapper {
		background-color: rgba(0, 0, 0, 0);
		background-image: none;
		background-repeat: repeat;
		background-size: auto;
		padding: 3rem 2rem 2rem;
		position: static;
		left: 0%;
	}

	.otg-cards {
		grid-row-gap: 2rem;
		flex-direction: column;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		left: 0%;
	}

	.otg-cards__text {
		grid-row-gap: 1rem;
	}

	.otg-cards__item {
		flex-direction: column;
		align-items: center;
	}

	.otg-cards__item-text {
		text-align: center;
		max-width: 75vw;
	}

	.otg-cards__title {
		margin-top: 1rem;
		line-height: 1.4;
	}

	.otg-cards__p {
		margin-top: 10px;
	}

	.otg-cards__link {
		float: none;
		justify-content: center;
		width: 70%;
	}

	.otg {
		margin-bottom: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.otg__title {
		text-align: left;
	}

	.otg__title.title-tablet {
		text-align: center;
	}

	.otg-blurb.services {
		margin-top: 40px;
		margin-bottom: 30px;
		padding-top: 60px;
	}

	.otg-blurb.services.aboutus {
		margin-top: 0;
		padding-top: 0;
	}

	.otg-blurb.news {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 40px;
	}

	.otg-blurb.news.aboutus {
		margin-top: 0;
		padding-top: 0;
	}

	.slide__title {
		font-size: 2rem;
	}

	.slideshow__nav {
		bottom: 0;
	}

	.slideshow__arrow-left {
		width: 30px;
		height: 30px;
		margin-left: 10px;
		position: absolute;
		top: auto;
		bottom: -6.4%;
		left: 0%;
		right: auto;
	}

	.slide__text {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 0.95rem;
		line-height: 1.2rem;
	}

	.slideshow__icon {
		z-index: 2000;
		background-color: rgba(255, 255, 255, 0);
		justify-content: center;
		align-items: center;
		width: 2rem;
		height: 2rem;
		margin-left: 0;
		margin-right: 0;
		font-size: 2.25rem;
		position: relative;
		bottom: 0;
		overflow: visible;
	}

	.slideshow__arrow-right {
		width: 30px;
		max-width: none;
		height: 30px;
		margin-right: 10px;
		top: auto;
		bottom: -6.4%;
		left: auto;
		right: 0%;
	}

	.slideshow__scrolldown {
		display: none;
	}

	.slideshow__scrolldown-arrow {
		margin-top: 175px;
		margin-right: 5px;
		font-size: 28px;
	}

	.slideshow {
		flex: 0 auto;
		height: 500px;
		margin-top: 0;
	}

	.slide__contents {
		align-items: flex-start;
		min-width: 0;
		max-width: none;
		margin-bottom: 40px;
		margin-left: 2rem;
		margin-right: 1rem;
		display: flex;
		position: static;
	}

	.main-nav__link-underline {
		display: none;
	}

	.mega-menu__title {
		color: var(--black);
		margin-bottom: 0;
		font-weight: 400;
		text-decoration: underline;
	}

	.subnav {
		justify-content: space-between;
	}

	.main-nav__link,
	.main-nav__link-wrap {
		display: block;
	}

	.subnav__button {
		margin-left: auto;
	}

	.main-nav__menu {
		display: none;
	}

	.main-nav__menu-wrapper {
		align-items: center;
	}

	.slideshow__mask {
		height: 500px;
		margin-top: 0;
	}

	.social-cta {
		flex-direction: column;
		width: 100%;
		margin-left: 0;
		padding: 1rem;
		left: 0%;
	}

	.main-nav__mobile-trigger {
		width: 3rem;
		height: 3rem;
		display: block;
	}

	.main-nav__mobile-icon {
		width: 100%;
		height: 100%;
		line-height: 1;
	}

	.mobile-nav,
	.mobile-nav__link {
		display: block;
	}

	.mobile-nav__sublist {
		margin-bottom: 0;
		padding-left: 0;
	}

	.mobile-nav__list {
		display: block;
	}

	.slide-wrapper {
		background-image: linear-gradient(83deg, rgba(0, 0, 0, 0.3) 8%, rgba(255, 255, 255, 0) 65%), url('/20181016082423/assets/images/slideshow-image-1.jpg');
		background-position: 0 0, 50% 0;
	}

	.slide-wrapper._1 {
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 10%, rgba(255, 255, 255, 0.2) 85%), var(--website-hero-bg-image);
		background-position: 0 0, 0 0;
		background-repeat: repeat, repeat;
		background-size: auto, auto;
		height: 500px;
	}

	.slide {
		justify-content: center;
		align-items: flex-end;
		display: flex;
		position: absolute;
	}

	.loc__caption {
		width: 45vw;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.slide__text2 {
		font-size: 0.95rem;
		line-height: 1.2rem;
	}

	.slide__text2.name-tablet {
		margin-bottom: 0;
		margin-left: 40px;
	}

	.tertiarybtnnew.sections {
		align-self: flex-start;
	}

	.dog1 {
		flex: 0 auto;
		width: auto;
		height: auto;
		position: relative;
		bottom: -36px;
	}

	.image {
		width: auto;
		max-width: none;
		height: 200px;
		position: relative;
	}

	.promo {
		grid-row-gap: 2rem;
		flex-direction: column;
	}

	.promo.dog-style {
		flex-flow: row;
		align-items: center;
		width: 100%;
		max-width: none;
		height: auto;
		margin-top: 0;
		margin-bottom: -85px;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.text-cta-2 {
		width: auto;
		font-size: 12px;
	}

	.button-with-arrow-copy {
		width: 87%;
	}

	.financialeducation {
		margin-bottom: 0;
		padding: 0 2rem 1rem;
		left: 0%;
	}

	.div-block-5 {
		height: auto;
	}

	.div-block-2 {
		width: 33%;
	}

	.heading-2 {
		color: #374957;
		font-size: 18px;
	}

	.image-4 {
		width: 100%;
	}

	.hamburger-line {
		background-color: #000;
		width: 30px;
		height: 3px;
		padding-bottom: 0;
		padding-right: 0;
	}

	.hamburger-line.middle {
		margin-top: 4px;
		margin-bottom: 4px;
	}

	.search-wrap {
		z-index: 2147483647;
		background-color: #fff;
		justify-content: space-between;
		align-items: center;
		padding: 20px 40px;
		display: flex;
		position: fixed;
		top: 0%;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.hamburger {
		cursor: pointer;
		display: block;
	}

	.form-block {
		width: 80%;
		margin-bottom: 0;
	}

	.uui-navbar07_dropdown-toggle-2-copy {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 24px;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar07_dropdown-toggle-2-copy:hover {
		color: #101828;
	}

	.uui-dropdown-icon-3-copy {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.uui-navbar07_dropdown-link-list-2-copy {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.uui-navbar07_dropdown-link-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar07_dropdown-link-2:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar07_menu-dropdown-2-copy {
		width: 100%;
		position: relative;
	}

	.uui-navbar07_dropdown-list-2-copy {
		position: static;
		overflow: hidden;
	}

	.uui-navbar07_dropdown-list-2-copy.w--open {
		box-shadow: none;
		border-style: none;
		border-color: rgba(0, 0, 0, 0);
		width: auto;
		padding: 0;
	}

	.heading-19 {
		align-self: flex-start;
		padding-left: 20px;
	}

	.div-block-146 {
		height: 50vh;
		margin-top: 0;
		padding-top: 0;
	}

	.title-container {
		width: auto;
		max-width: 100%;
		margin-left: auto;
		padding-left: 3%;
		padding-right: 3%;
	}

	.pagetitle {
		padding-left: 3%;
	}

	.pagetitle.inside-top-title {
		padding-left: 20px;
	}

	.textwithimage {
		width: 65%;
	}

	.page-title-main {
		background-image: url('/20181016082423/assets/images/AdobeStock_446315472-copy.jpg');
		background-position: 50% 30%;
		background-repeat: no-repeat;
		background-size: cover;
		height: 250px;
	}

	.page-title-main.travel-with-debit-card {
		background-position: 50% 0;
	}

	.inside-content {
		max-width: 100%;
		padding: 2rem 1rem 3rem;
	}

	.left-side-col {
		width: 100%;
		margin-top: 1.5rem;
		margin-left: 0%;
		display: block;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.paragraph-9 {
		font-size: 16px;
		line-height: 26px;
	}

	.pagetitlemain {
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	.pagetitlemain.travel-with-debit-card {
		background-position: 50% 0;
	}

	.bold-text {
		text-align: left;
	}

	.header-right-upper-wrap {
		width: auto;
		height: auto;
		margin-right: 0%;
		padding-top: 0;
		padding-right: 0;
	}

	.header-content-wrap {
		justify-content: space-between;
		width: auto;
		margin-right: 3%;
		display: flex;
	}

	.nav-bar-widget-2 {
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 10px;
		display: flex;
	}

	.main-nav__logo-img-2 {
		min-width: 240px;
	}

	.text-block-44 {
		font-size: 14px;
	}

	.text-block-58.hide {
		display: none;
	}

	.ths01-search-button:hover {
		background-color: #9d7d09;
	}

	.subnav__link-wrapper-2 {
		margin-bottom: 10px;
		margin-right: 0;
	}

	.uui-navbar07_dropdown-link-list-2-copy-2 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.subnav-search-wrap {
		color: #fff;
		background-color: #f9f9f9;
		flex-direction: row;
		align-items: stretch;
		width: 100%;
		height: 100vh;
		margin-top: 0;
		padding-top: 8px;
		padding-left: 8px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.3);
	}

	.uui-navbar07_dropdown-link-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar07_dropdown-link-3:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.main-nav__link-2 {
		text-align: left;
		display: block;
	}

	.uui-navbar07_dropdown-toggle-2-copy-2 {
		color: #101828;
		align-items: center;
		margin-right: 24px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 24px;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar07_dropdown-toggle-2-copy-2:hover {
		color: #101828;
	}

	.main-nav-link-3 {
		text-align: left;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.uui-navbar07_item-heading-3 {
		padding-left: 24px;
	}

	.topbar-container {
		flex-flow: column;
		justify-content: flex-end;
		align-self: flex-end;
		width: auto;
		height: auto;
		margin-top: 0;
		padding-right: 0;
	}

	.header-right-wrap {
		justify-content: center;
		height: auto;
		margin-right: 20px;
		display: flex;
		position: static;
	}

	.text-block-43 {
		font-size: 14px;
	}

	.upper-left-wrap {
		height: 100%;
		display: block;
	}

	.section-9 {
		top: 0;
	}

	.subnav-wrap {
		flex-direction: column;
	}

	.ths01-search {
		width: 200px;
		margin-left: 0;
	}

	.mobile-menu-toggle-2 {
		float: none;
		color: #000;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		margin-top: 30px;
		margin-left: 20px;
		margin-right: 3%;
		padding: 0;
		transition: all 0.2s;
		display: flex;
	}

	.mobile-menu-toggle-2:hover {
		color: #4578bc;
		background-color: rgba(0, 0, 0, 0);
	}

	.mobile-menu-toggle-2.w--open {
		color: #000;
		background-color: rgba(0, 0, 0, 0);
	}

	.social-list {
		padding-right: 0;
	}

	.logo-wrapper-2 {
		width: auto;
		margin-bottom: 0.375rem;
	}

	.main-nav__link-wrap-2 {
		margin-left: 24px;
		margin-right: 24px;
		display: block;
	}

	.menu-icon-2 {
		text-align: center;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		margin-left: auto;
		margin-right: auto;
		padding: 5px 0 0;
		font-size: 2.15rem;
		line-height: 2.15rem;
		display: flex;
	}

	.menu-icon-2:hover {
		color: #3c4a20;
	}

	.sliderdiv {
		flex-flow: column;
		flex: 0 auto;
		margin-top: 0;
		display: flex;
	}

	.nav-link {
		text-align: left;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.nav-link.mobile {
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
		border-bottom-style: none;
		border-bottom-color: rgba(255, 255, 255, 0.1);
		justify-content: space-between;
		width: 88%;
		padding: 20px;
		font-family: Open Sans, sans-serif;
		font-size: 0.9rem;
		font-weight: 400;
		line-height: 1rem;
		display: block;
	}

	.nav-link.mobile:hover {
		background-color: rgba(0, 0, 0, 0);
		background-image: none;
		transform: none;
	}

	.nav-link.mobile.w--current {
		color: #f9b604;
		transform: scale(1);
		box-shadow: inset 6px 0 #f9b604;
	}

	.header-nav-inner-content {
		border-bottom-color: rgba(110, 110, 110, 0.15);
		flex-flow: row;
		align-self: auto;
		width: 100%;
		padding-top: 0.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.alert-box-close-button {
		margin-right: 0;
		padding-right: 0;
		right: 2rem;
	}

	.secondary-nav-icon {
		background-color: rgba(0, 0, 0, 0);
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
	}

	.header-nav-wrappper {
		justify-content: space-between;
		display: flex;
	}

	.search-input-exit {
		width: auto;
		margin-right: 0;
		padding-right: 0;
		display: block;
	}

	.navbar-wrapper {
		justify-content: flex-start;
	}

	.div-block-101 {
		padding-top: 0.5rem;
	}

	.navbar {
		background-color: rgba(0, 0, 0, 0);
		padding-top: 10px;
	}

	.search {
		align-items: stretch;
		width: auto;
	}

	.secondary-nav-icon-copy {
		background-color: rgba(0, 0, 0, 0);
		width: auto;
		height: 18px;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px 0 2px 10px;
	}

	.mobile-arrow {
		color: #fff;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
		font-family: Montserrat, sans-serif;
		font-size: 0.85rem;
		font-weight: 500;
		line-height: 1em;
	}

	.brand-image {
		width: 280px;
	}

	.nav-menu {
		background-color: #163b68;
	}

	.search-bar-wrap {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.menu-button {
		padding: 0 0 0 0;
	}

	.menu-button.w--open {
		color: #163b68;
		background-color: rgba(0, 0, 0, 0);
		border-radius: 3px;
	}

	.top-header-nav-wrapper {
		background-color: #176c71;
		padding: 0 2rem;
	}

	.search-input-form-2 {
		z-index: 1000;
		opacity: 0;
		width: 0;
		margin-bottom: 0;
		display: none;
		position: relative;
		overflow: hidden;
	}

	.dropdown {
		display: none;
	}

	.mobile-menu-arrow {
		color: #000;
		background-color: rgba(30, 98, 149, 0.1);
		border-left: 1px solid rgba(77, 76, 76, 0.25);
		justify-content: center;
		align-items: center;
		width: 12%;
		display: flex;
	}

	.mobile-menu-arrow:hover {
		background-color: #1e6295;
	}

	.search-input {
		letter-spacing: 0.5px;
		margin-right: 1rem;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.85rem;
		font-weight: 400;
	}

	.brand {
		margin-left: 0;
	}

	.search-button-2 {
		z-index: 1100;
		color: #333;
		background-color: #1e6295;
		margin-left: -14px;
		position: relative;
	}

	.search-button-2:hover {
		background-color: #044f5f;
	}

	.icon-8 {
		color: #163b68;
		border-radius: 3px;
		font-size: 2rem;
	}

	.alert-header-content-wrapper {
		align-items: stretch;
		margin-left: 0;
		margin-right: 0;
		padding: 0.75rem 0;
	}

	.two-part-mobile-nav-wrapper {
		border-top: 1px #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
		justify-content: space-between;
		display: flex;
	}

	.two-part-mobile-nav-wrapper.top {
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: solid;
		border-bottom-color: rgba(255, 255, 255, 0.1);
		justify-content: space-between;
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: #1e6295;
	}

	.search-bar {
		border-bottom: 1px solid rgba(110, 110, 110, 0.15);
		display: none;
		top: 0;
	}

	.search-section {
		margin-left: auto;
		margin-right: 2rem;
		display: none;
	}

	.alert-header-container {
		border: 1px #000;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.div-block-152 {
		padding-top: 0.5rem;
	}

	.icon-9 {
		color: #163b68;
		border: 1px solid #d9d9d9;
		border-radius: 40px;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		margin-top: 38px;
		font-size: 2rem;
		display: flex;
	}

	.div-block-153 {
		align-self: center;
	}

	.nav-dropdown {
		background-color: #252525;
		display: block;
		position: relative;
	}

	.nav-search {
		display: none;
	}

	.uui-navbar07_dropdown-link-list-2-copy-3 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.subnav-wrap-2 {
		flex-direction: column;
	}

	.uui-dropdown-icon-3-copy-2 {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 10px;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.nav-links-wrapper {
		flex: 1;
		justify-content: flex-end;
		display: block;
	}

	.subnav-search-wrap-2 {
		color: #fff;
		background-color: #f9f9f9;
		flex-direction: row;
		align-items: stretch;
		width: 100%;
		height: 100vh;
		margin-top: 0;
		padding-top: 8px;
		padding-left: 8px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.3);
	}

	.nav-head__logo-img {
		width: 300px;
	}

	.uui-navbar07_dropdown-toggle-2-copy-3 {
		color: #101828;
		align-items: center;
		margin-right: 24px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 24px;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar07_dropdown-toggle-2-copy-3:hover {
		color: #101828;
	}

	.nav-dropdown__toggle {
		justify-content: space-between;
		display: flex;
	}

	.nav-head__logo {
		max-width: 200px;
	}

	.ths01-search-button-2:hover {
		background-color: #9d7d09;
	}

	.nav-links {
		grid-row-gap: 1rem;
		text-align: left;
		background-color: #252525;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		display: block;
	}

	.text-block-61.hide {
		display: none;
	}

	.text-block-62 {
		font-size: 14px;
	}

	.nav-links__arrow {
		background-color: #1b1b1b;
		background-image: url('/20181016082423/assets/images/angle-right_white_75.svg');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 10px;
		border-left: 1px solid rgba(255, 255, 255, 0.25);
		flex: 0 20%;
	}

	.nav-links__item {
		color: #fff;
		text-transform: none;
		background-color: #016e51;
		flex: 1;
		padding: 1.5rem;
		font-size: 1.4rem;
	}

	.nav-links__item.is--hidden {
		display: block;
	}

	.uui-navbar07_item-heading-4 {
		padding-left: 24px;
	}

	.text-block-63 {
		font-size: 14px;
	}

	.nav-head__container {
		grid-column-gap: 2rem;
		padding: 0.5rem 4vw;
	}

	.uui-navbar07_dropdown-link-4 {
		padding-left: 0;
		padding-right: 0;
	}

	.uui-navbar07_dropdown-link-4:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.nav-head {
		height: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.main-nav__link-3 {
		text-align: left;
		display: block;
	}

	.namediv {
		order: -1;
	}

	.slider-img {
		justify-content: flex-start;
		align-items: flex-end;
		width: 330px;
		height: 100%;
		display: flex;
	}

	.slider-promo {
		flex-flow: column;
		justify-content: flex-end;
		align-items: flex-start;
		width: auto;
		height: 100%;
		margin-left: 0;
		display: flex;
		margin-right: 1.4%;
	}

	.helpbutton {
		z-index: 1;
		width: 100%;
		position: relative;
	}

	.helpcon {
		color: #101828;
		align-items: center;
		margin-right: 24px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 24px;
		font-size: 1.125rem;
		display: flex;
	}

	.helpcon:hover {
		color: #101828;
	}

	.helpmain {
		position: static;
		overflow: hidden;
	}

	.helpmain.w--open {
		box-shadow: none;
		border-style: none;
		border-color: rgba(0, 0, 0, 0);
		width: auto;
		padding: 0;
	}

	.helpmain.abouthelp.w--open {
		z-index: 2000;
		display: block;
		position: absolute;
		overflow: hidden;
	}

	.help_dropdown {
		padding-top: 0;
		padding-bottom: 0;
	}

	.help-dropdown_link {
		padding-left: 0;
		padding-right: 0;
	}

	.help-dropdown_link:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.div-block-155 {
		flex-flow: column;
		align-items: flex-start;
	}

	.about-us__p-copy {
		text-align: left;
		margin-top: 0;
	}

	.otg-rates {
		margin-top: 1rem;
		margin-bottom: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.topbar-container-mobile {
		flex-flow: column;
		justify-content: flex-end;
		align-self: flex-end;
		width: auto;
		height: auto;
		margin-top: 0;
		padding-right: 0;
	}

	.subnav__link-wrapper-2-mobile {
		margin-bottom: 10px;
		margin-right: 0;
	}

	.mobile-search {
		display: flex;
	}

	.searchicon {
		margin-top: 10px;
	}

	.searchicon.mobile {
		margin-top: 0;
		margin-left: auto;
		margin-right: 2rem;
		padding-left: 0;
	}

	.search-button-icon {
		background-size: 35px;
		width: 15px;
		margin-top: 0;
	}

	.search-button-icon:hover {
		background-size: 35px;
	}

	.exit-button-icon {
		background-size: 35px;
		margin-top: 5px;
	}

	.exit-button-icon:hover {
		background-size: 35px;
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		width: 100vw;
		margin: 100px 0 0 0%;
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.search-input-3 {
		padding-left: 0;
	}
}

@media screen and (max-width: 767px) {
	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	.navbar__logo-link {
		padding-left: 0;
	}

	.quick-links__card {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.footer-wrapper {
		width: 100%;
		height: auto;
		padding-top: 1.5rem;
	}

	.about-us__text {
		order: -1;
		align-self: auto;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 0;
		padding-left: 0;
	}

	.about-us__text.mobile {
		display: none;
	}

	.about-us__text.mobile-view {
		order: 1;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-left: 0;
	}

	.about-us__img-wrap {
		flex-shrink: 0;
		width: auto;
		display: block;
	}

	.footer-content {
		grid-template-rows: auto auto;
	}

	.footer-content.fdic {
		margin-top: 2rem;
	}

	.footer-content.signoff-container {
		text-align: center;
		flex-flow: column wrap;
		justify-content: space-around;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		padding-top: 0.5rem;
		padding-bottom: 1rem;
		display: flex;
	}

	.footer-left-content-wrapper {
		border-bottom: 1px rgba(255, 248, 225, 0.25);
		width: 100%;
	}

	.footer-right-content-wrap {
		border-bottom: 1px rgba(255, 248, 225, 0.25);
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.footer-nav-item {
		margin-top: 0;
		margin-bottom: 0;
	}

	.signoff__p {
		font-size: 0.9rem;
	}

	.footer-content__item.footer-cb-left {
		width: 40%;
		margin-right: 0;
	}

	.footer-content__item.footer-cb-left.top-cb-static {
		margin-bottom: 2rem;
	}

	.footer-content__item.footer-logo-left {
		width: 50%;
	}

	.footer-content__item.is--centered {
		padding-left: 0;
	}

	.footer-nav-list {
		margin-left: 0;
	}

	.signoff-link-wrapper {
		margin-left: 0;
		display: flex;
	}

	.footer-middle-wrapper {
		justify-content: center;
		width: 100%;
		margin-bottom: 1rem;
		display: flex;
	}

	.equal-housing-lender-img {
		float: none;
		margin-left: 10px;
		margin-right: 10px;
	}

	.certifications-wrapper {
		display: flex;
	}

	.certifications-icons {
		width: auto;
		display: block;
	}

	.member-fdic-img {
		float: none;
		margin-left: 10px;
		margin-right: 10px;
	}

	.resources-list-item {
		width: 100%;
		margin-bottom: 1rem;
	}

	.inside-row {
		margin-left: 0;
	}

	.image-banner {
		background-position: 50% 0;
	}

	.breadcrumb-list {
		padding-left: 1.5rem;
	}

	.breadcrumb-list.full-mob {
		padding-left: 0;
	}

	.breadcrumb-list.mob-right,
	.breadcrumb-list.mob-right-copy {
		padding-left: 1.8rem;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.h5-heading-link {
		font-size: 1.125rem;
	}

	.inside-content-background {
		padding: 1rem 2rem 2rem;
	}

	.h3-heading-link {
		font-size: 1.5rem;
	}

	.content-container.main-content {
		padding-right: 0;
	}

	.h2-heading-link {
		font-size: 1.75rem;
	}

	.imagecon.recent-blog-post {
		width: auto;
	}

	.inside-page-header-wrap {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.h1-heading-link {
		font-size: 1.75rem;
	}

	.cb-wrapper {
		width: 32%;
	}

	.page-container {
		background-position: 50% 44%;
		background-size: 250px;
		margin-top: 30px;
	}

	.c-button.push--down.is--white {
		margin-top: 0;
	}

	.about-us {
		grid-column-gap: 0rem;
		grid-row-gap: 0rem;
		align-items: center;
		max-width: none;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.about-us__heading {
		font-size: 2rem;
	}

	.about-us__heading.blue {
		color: var(--white);
		padding-left: 0;
		font-size: 2rem;
	}

	.about-us__p {
		width: 90%;
		max-width: none;
		margin-top: 20px;
		margin-bottom: 1.5rem;
		font-size: 1rem;
	}

	.quick-links__heading {
		margin-top: 0;
	}

	.quick-links {
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr 1fr;
	}

	.quick-links-wrapper {
		padding-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.otg-cards {
		width: 100%;
		height: auto;
		padding: 0;
	}

	.otg-cards__img-wrap {
		max-height: 75vh;
		display: block;
	}

	.otg-cards__text {
		flex-flow: column;
		align-self: center;
		align-items: center;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.otg-cards__item {
		width: auto;
	}

	.otg-cards__p {
		width: 100%;
	}

	.otg-cards__link {
		width: auto;
	}

	.otg {
		grid-row-gap: 2rem;
		flex-direction: column;
		display: flex;
	}

	.otg__title {
		text-align: center;
	}

	.otg__title.fe {
		margin-left: 15px;
	}

	.otg__title.home-about-title,
	.otg__title.left-about {
		text-align: left;
	}

	.otg__title.fe-copy {
		margin-left: 15px;
	}

	.otg__title.home-about-title-copy {
		text-align: left;
	}

	.otg-blurb.services {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.otg-blurb.news {
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
	}

	.loc__p {
		font-size: 0.9rem;
	}

	.slide__title {
		font-size: 2rem;
	}

	.slideshow__nav {
		font-size: 0.75rem;
	}

	.slideshow__icon {
		font-size: 2rem;
	}

	.slideshow__scrolldown-arrow {
		margin-top: 125px;
		margin-right: 5px;
	}

	.slideshow {
		height: 50vh;
	}

	.slide__contents {
		margin-left: 4rem;
	}

	.main-nav__logo-img {
		width: 175px;
		min-width: 0;
	}

	.subnav__button-text {
		text-align: center;
		white-space: break-spaces;
		display: block;
	}

	.subnav {
		justify-content: flex-end;
	}

	.subnav__link {
		text-align: center;
		font-size: 0.85rem;
	}

	.nav-wrapper {
		height: 100px;
	}

	.subnav__button {
		align-items: center;
		margin-left: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 0.8rem;
		display: inline-block;
	}

	.main-nav__menu {
		margin-top: 142px;
	}

	.subnav-wrapper,
	.main-nav__menu-wrapper {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.slideshow__mask {
		margin-top: -45px;
	}

	.social-cta {
		width: 100%;
		height: auto;
	}

	.social-cta__title {
		font-size: 1.5rem;
	}

	.social-cta__p {
		font-size: 1rem;
	}

	.signoff-wrapper {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.main-nav__mobile-trigger {
		width: 2rem;
		height: 2rem;
	}

	.otg-cards__img {
		max-width: 100%;
	}

	.slide-wrapper {
		background-image: linear-gradient(83deg, rgba(0, 0, 0, 0.3) 20%, rgba(255, 255, 255, 0) 75%), url('/20181016082423/assets/images/slideshow-image-1.jpg');
		background-position: 0 0, 50% 0;
		background-size: auto, cover;
	}

	.subnav__link-wrapper {
		display: none;
	}

	.loc__caption {
		text-align: center;
		justify-content: center;
		align-items: center;
		width: 50vw;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
	}

	.text-block-23 {
		font-size: 0.85rem;
	}

	.titleline.fe.center,
	.titleline.fe.center-copy {
		margin-left: 15px;
	}

	.dog1 {
		margin-bottom: 3px;
		margin-right: 0;
		bottom: 9px;
	}

	.image {
		width: auto;
		height: 220px;
	}

	.promo {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.promo.dog-style {
		background-color: #0057a5;
		flex-flow: column;
		max-width: none;
		height: 350px;
		margin-top: 0;
		margin-bottom: -70px;
		padding-top: 40px;
	}

	.about-us__heading-2 {
		font-size: 2rem;
	}

	.team_content-2 {
		right: 15%;
	}

	.team_image-2 {
		height: 28rem;
	}

	.team_name-copy-2 {
		font-size: 1.25rem;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.heading-11 {
		font-size: 40px;
		line-height: 40px;
	}

	.team_name-copy {
		font-size: 1.25rem;
	}

	.financialeducation {
		margin-top: 0;
		padding-top: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.div-block-12 {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.div-block-2 {
		width: 80%;
		margin-bottom: 0;
	}

	.image-4 {
		width: 100%;
	}

	.search-wrap {
		padding-left: 20px;
		padding-right: 20px;
	}

	.uui-navbar07_dropdown-toggle-2-copy {
		font-size: 1rem;
	}

	.uui-navbar07_dropdown-link-list-2-copy {
		grid-row-gap: 0.5rem;
		max-width: none;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.heading-19 {
		color: var(--white);
		align-self: flex-start;
		padding-left: 0;
	}

	.title-container {
		padding-top: 15px;
	}

	.pagetitle {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.pagetitle.inside-top-title {
		padding-left: 15px;
	}

	.page-title-main {
		background-position: 50% 0;
	}

	.inside-content {
		padding-bottom: 2rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.div-block-150 {
		flex-flow: column;
	}

	.div-block-149-copy {
		width: auto;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.pagetitlemain {
		background-image: none;
		margin-top: -14px;
	}

	.header-right-upper-wrap {
		border-bottom: 0 solid rgba(0, 0, 0, 0.1);
		justify-content: flex-end;
		width: 100%;
		padding-left: 3vw;
		padding-right: 0;
		transition: all 0.3s;
		display: none;
		position: static;
		top: 0;
		left: 0;
		right: 0;
	}

	.header-content-wrap {
		padding-top: 0;
		padding-bottom: 10px;
		transition: all 0.3s;
		display: flex;
	}

	.nav-bar-widget-2 {
		width: auto;
		top: auto;
		bottom: 75px;
	}

	.main-nav__logo-img-2 {
		width: 175px;
		min-width: 0;
	}

	.text-block-44 {
		display: none;
	}

	.subnav__link-wrapper-2 {
		display: flex;
	}

	.uui-navbar07_dropdown-link-list-2-copy-2 {
		grid-row-gap: 0.5rem;
		max-width: none;
		padding-top: 0;
		padding-bottom: 0;
	}

	.subnav-search-wrap {
		margin-top: 0;
	}

	.uui-navbar07_dropdown-toggle-2-copy-2 {
		font-size: 1rem;
	}

	.topbar-container.display-none {
		padding-top: 0;
		padding-bottom: 0;
	}

	.header-right-wrap {
		padding-top: 1rem;
	}

	.text-block-43 {
		display: none;
	}

	.section-9 {
		height: 100px;
		padding-bottom: 0;
		top: 0;
	}

	.mobile-menu-toggle-2 {
		margin-top: 40px;
		margin-left: 0;
	}

	.mobile-menu-toggle-2:hover {
		background-image: none;
	}

	.social-list {
		padding-left: 10px;
		padding-right: 10px;
	}

	.logo-wrapper-2 {
		width: auto;
		top: 0.25rem;
	}

	.contact-item {
		padding-left: 10px;
		padding-right: 10px;
	}

	.sliderdiv.hide-slide-mob {
		display: none;
	}

	.nav-link.mobile {
		width: 84%;
		padding-left: 20px;
	}

	.header-nav-inner-content {
		padding: 0.5rem 1.5rem;
	}

	.alert-box-close-button {
		margin-right: 0;
		right: 1.5rem;
	}

	.button {
		font-size: 0.8rem;
	}

	.search-bar-wrap {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.top-header-nav-wrapper {
		justify-content: space-between;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.mobile-menu-arrow {
		background-color: rgba(30, 98, 149, 0.1);
		width: 16%;
	}

	.search-input {
		margin-right: 0.5rem;
	}

	.brand {
		width: 125px;
		padding-left: 0;
	}

	.alert-header-text {
		padding-right: 0;
	}

	.alert-header-content-wrapper {
		margin-left: 0;
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 1em;
	}

	.alert-header-inner-text {
		font-size: 0.8rem;
	}

	.search-bar {
		display: none;
		top: 0;
	}

	.search-section {
		margin-right: 1.5rem;
	}

	.alert-header-container {
		justify-content: space-between;
		padding-left: 1.5rem;
		padding-right: 0;
	}

	.button-4 {
		font-size: 0.8rem;
	}

	.icon-9 {
		margin-top: 0;
	}

	.uui-navbar07_dropdown-link-list-2-copy-3 {
		grid-row-gap: 0.5rem;
		max-width: none;
		padding-top: 0;
		padding-bottom: 0;
	}

	.subnav-search-wrap-2 {
		margin-top: 0;
	}

	.nav-head__logo-img {
		width: 220px;
		margin-top: 20px;
	}

	.uui-navbar07_dropdown-toggle-2-copy-3 {
		font-size: 1rem;
	}

	.text-block-62,
	.text-block-63 {
		display: none;
	}

	.nav-head__container {
		padding-bottom: 1rem;
		padding-left: 0;
		padding-right: 0;
	}

	.helpbutton {
		margin-left: 0;
	}

	.helpcon {
		font-size: 1rem;
	}

	.help_dropdown {
		grid-row-gap: 0.5rem;
		max-width: none;
		padding-top: 0;
		padding-bottom: 0;
	}

	.divider-gold._1st {
		display: none;
	}

	.titleline-copy.fe.center,
	.titleline-copy.fe.center-copy {
		margin-left: 15px;
	}

	.about-us__p-copy {
		width: 90%;
		max-width: none;
		margin-top: 20px;
		margin-bottom: 1.5rem;
		font-size: 1rem;
	}

	.otg-rates {
		grid-row-gap: 2rem;
		flex-direction: column;
		display: flex;
	}

	.topbar-container-mobile.display-none {
		padding-top: 0;
		padding-bottom: 0;
	}

	.topbar-container-mobile.mobileonly {
		display: none;
	}

	.subnav__link-wrapper-2-mobile {
		display: flex;
	}

	.mobile-search {
		margin-top: 20px;
	}

	.searchicon.mobile {
		margin-top: 0;
		margin-left: auto;
	}

	.search-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.search-button-icon:hover {
		background-size: 30px;
	}

	.exit-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.exit-button-icon:hover {
		background-size: 30px;
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		width: 100vw;
		margin-top: 100px;
		margin-right: 0;
		padding-left: 2rem;
		padding-right: 2rem;
		display: none;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-input-3 {
		padding-left: 0;
	}
}

@media screen and (max-width: 550px) {
	.nav-links-wrapper {
		display: none;
	}
}

@media screen and (max-width: 479px) {
	.quick-links__card {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.about-us__p-copy.cbtext h2 {
		font-size: 1.5rem !important;
		line-height: 1.6rem !important;
	}

	.menu-icon-2 {
		padding-top: 11px;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.navbar__logo-link {
		width: 150px;
		max-width: 175px;
		padding-left: 0;
	}

	.primary-button {
		margin-top: 1rem;
		margin-right: 0;
		font-size: 0.8rem;
	}

	.quick-links__icon {
		width: 3.5rem;
		height: 3.5rem;
	}

	.about-us__text {
		margin-top: 40px;
		display: block;
	}

	.about-us__text.mobile {
		flex-flow: column;
		display: block;
	}

	.about-us__text.mobile-view {
		margin-top: 0;
		padding-left: 0;
		display: block;
	}

	.about-us__img-wrap {
		width: auto;
	}

	.footer-content {
		grid-template-rows: auto auto auto auto;
		grid-template-columns: 1fr;
	}

	.footer-content.signoff-container {
		margin-left: auto;
		margin-right: auto;
		padding-left: 2rem;
		padding-right: 2rem;
		display: block;
	}

	.footer-left-content-wrapper {
		flex-direction: row;
		align-items: center;
		padding-bottom: 0;
	}

	.footer-right-content-wrap {
		border-bottom-color: rgba(255, 248, 225, 0.25);
		flex-direction: column;
		padding-bottom: 0;
	}

	.footer-signoff-link {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.footer-nav-item {
		text-align: center;
		margin-bottom: 0;
	}

	.footer-content-wrapper {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.signoff__grip-link {
		background-size: 140px 29px;
		flex: none;
	}

	.signoff__p {
		margin-bottom: 0.5rem;
		margin-right: 0;
	}

	.footer-content__item.footer-cb-left,
	.footer-content__item.footer-logo-left {
		align-items: center;
		width: 100%;
	}

	.footer-content__item.is--centered {
		align-items: center;
	}

	.footer-nav-list {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	.signoff-link-wrapper {
		flex-wrap: wrap;
		justify-content: center;
	}

	.footer-link-wrapper,
	.footer-link-wrapper.first {
		justify-content: center;
	}

	.footer-icon-block {
		width: 1.5rem;
		height: 1.5rem;
	}

	.quick-links__img {
		width: auto;
	}

	.footer-divider-line {
		width: 15%;
	}

	.image-banner {
		background-image: url('/20181016082423/assets/images/AdobeStock_446315472-copy.jpg');
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: cover;
		height: 225px;
	}

	.image-banner.travel-with-debit-card {
		background-position: 50% 0;
	}

	.breadcrumb-list {
		flex-wrap: wrap;
		align-items: flex-end;
		display: flex;
	}

	.breadcrumb-list.mob-right-copy {
		padding-left: 1rem;
	}

	.div-block-140 {
		width: 100%;
		height: auto;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.block-quote {
		padding-left: 15px;
		padding-right: 15px;
	}

	.inside-content-background {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.content-container.main-content {
		padding-left: 0;
		padding-right: 0;
	}

	.text-block-21 {
		font-size: 0.8rem;
		line-height: 0.8rem;
	}

	.breadcrumb-list-item {
		margin-bottom: 0.5rem;
	}

	.breadcrumb-link {
		font-size: 0.8rem;
		line-height: 0.8rem;
	}

	.paragraph-text {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	.imagecon {
		background-position: 20%;
	}

	.imagecon.recent-blog-post {
		height: 250px;
	}

	.inside-page-header-wrap {
		margin-bottom: 1rem;
	}

	.inside-page-header-wrap.full-mob-2 {
		padding-left: 1rem;
	}

	.button-styling {
		flex-wrap: wrap;
		padding-left: 0;
	}

	.secondary-button,
	.tertiary-button {
		margin-top: 1rem;
		margin-right: 0;
		font-size: 0.8rem;
	}

	.paragraph-text-link {
		font-size: 0.9rem;
	}

	.div-block-142 {
		flex-wrap: wrap;
	}

	.cb-wrapper {
		flex-wrap: nowrap;
		width: 100%;
		margin-bottom: 1.25rem;
	}

	.cb-wrapper.last-cb {
		margin-bottom: 0;
	}

	.page-container {
		background-position: 50%;
		margin-top: 0;
	}

	.about-us {
		width: 100%;
		height: auto;
		margin-top: 0;
		position: static;
	}

	.about-us__p {
		width: 100%;
	}

	.quick-links {
		grid-template-columns: 1fr;
	}

	.otg-cards {
		grid-row-gap: 1rem;
	}

	.otg-cards__text {
		padding-left: 0;
		padding-right: 0;
	}

	.otg-cards__title {
		font-size: 1.1rem;
	}

	.otg {
		margin-top: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.otg__title {
		text-align: center;
		font-size: 1.5rem;
	}

	.otg__title.lrft-about {
		text-align: left;
	}

	.otg__title.fe-copy {
		margin-left: 0;
	}

	.otg-blurb.services {
		padding-top: 60px;
		padding-left: 0;
	}

	.otg-blurb.services.aboutus {
		margin-top: 30px;
	}

	.otg-blurb.news {
		padding-top: 0;
		padding-left: 0;
	}

	.locations-wrapper {
		flex-direction: column;
		height: auto;
		display: flex;
	}

	.slide__title {
		font-size: 2rem;
		line-height: 1;
	}

	.slideshow__nav {
		z-index: 1000;
		font-size: 0.7rem;
		bottom: 42px;
	}

	.slideshow__arrow-left {
		display: none;
	}

	.slide__text {
		text-align: left;
	}

	.slideshow__arrow-right {
		display: none;
	}

	.slideshow__scrolldown-arrow {
		margin-top: 75px;
		margin-right: 0;
	}

	.slide__contents {
		margin-left: 1rem;
		margin-right: 1rem;
		padding: 1rem 0.5rem;
	}

	.main-nav__logo-img {
		min-width: 0;
	}

	.subnav {
		justify-content: center;
	}

	.nav-wrapper {
		height: 100px;
	}

	.main-nav__menu {
		margin-top: 172px;
	}

	.main-nav__logo {
		max-width: 175px;
		padding-left: 0;
	}

	.social-cta__title {
		font-size: 1.2rem;
	}

	.signoff {
		text-align: center;
		flex-direction: column;
		display: flex;
	}

	.c-quick-links.footerlinks {
		align-items: center;
	}

	.signoff-wrapper {
		margin-bottom: 2rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.otg-cards__img {
		max-width: 100%;
		max-height: 0%;
	}

	.slide-wrapper {
		background-image: linear-gradient(83deg, rgba(0, 0, 0, 0.3) 20%, rgba(255, 255, 255, 0) 88%), url('/20181016082423/assets/images/slideshow-image-1.jpg');
		background-position: 0 0, 50% 0;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
	}

	.slide-wrapper._1 {
		background-position: 62%, 0 0, 0 0;
	}

	.slide-wrapper._2 {
		background-position: 62% 100%, 0 0, 50% 100%;
	}

	.slide-wrapper._3 {
		background-position: 62% 100%, 0 0, 0 0;
	}

	.slide {
		align-items: flex-end;
		margin-bottom: 20px;
	}

	.loc__map-embed {
		height: 45vh;
	}

	.loc__caption {
		width: 100%;
		max-width: none;
		position: static;
	}

	.loc__title {
		font-size: 1.2rem;
	}

	.slide__text2 {
		text-align: left;
	}

	.tertiarybtnnew.searchbtn {
		justify-content: center;
		align-items: center;
		height: 50px;
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.titleline.fe.center-copy {
		margin-left: 0;
	}

	.image {
		height: 190px;
	}

	.promo {
		width: 100%;
		height: auto;
		position: static;
	}

	.promo.dog-style {
		flex-flow: column;
		height: 400px;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.padding-bottom {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.team_item_promo {
		width: 100%;
	}

	.two-box-promo,
	.threebox {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.div-block-12 {
		width: auto;
	}

	.image-3,
	.div-block-5 {
		border-radius: 0;
	}

	.text-block-3 {
		margin-left: 0;
		margin-right: 20px;
	}

	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.div-block-2 {
		width: 100%;
		margin-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.heading-2 {
		margin-top: 20px;
		margin-left: 0;
		margin-right: 20px;
		font-size: 20px;
		line-height: 25px;
	}

	.image-4 {
		width: 100%;
	}

	.uui-navbar07_item-heading-2 {
		color: #1f2b5e;
		font-size: 1rem;
	}

	.text-block-33,
	.text-block-33.no-line {
		font-size: 1.2rem;
	}

	.uui-navbar07_dropdown-link-2 {
		padding-left: 0;
	}

	.cta-button {
		margin-bottom: 16px;
	}

	.title-container {
		padding-top: 15px;
	}

	.pagetitle.inside-top-title.top-mobile {
		padding-left: 0;
	}

	.textwithimage {
		width: 100%;
		height: auto;
	}

	.list-1 {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	.list-1-icon {
		width: 30px;
		height: 25px;
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	.list-2-icon {
		width: 30px;
		font-size: 0.95rem;
		line-height: 1.35rem;
	}

	.list-3-icon {
		width: 30px;
	}

	.page-title-main {
		background-image: url('/20181016082423/assets/images/AdobeStock_446315472-copy.jpg');
		background-position: 50% 0;
		background-repeat: no-repeat;
		background-size: cover;
		height: 225px;
	}

	.page-title-main.travel-with-debit-card {
		background-position: 50% 0;
	}

	.inside-content {
		padding-top: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.pagetitlemain {
		height: auto;
	}

	.pagetitlemain.travel-with-debit-card {
		background-position: 50% 0;
	}

	.header-right-upper-wrap {
		justify-content: space-around;
	}

	.nav-bar-widget-2 {
		margin-top: 10px;
	}

	.main-nav__logo-img-2 {
		min-width: 0;
	}

	.main-nav__logo-2 {
		max-width: 175px;
		padding-left: 0;
	}

	.subnav-search-wrap {
		width: 100%;
		margin-top: auto;
	}

	.uui-navbar07_dropdown-link-3 {
		padding-left: 0;
	}

	.text-block-57 {
		font-size: 0.95rem;
	}

	.uui-navbar07_item-heading-3 {
		color: #00549d;
		font-size: 1rem;
	}

	.topbar-container {
		justify-content: center;
	}

	.topbar-container.display-none {
		display: none;
	}

	.header-right-wrap {
		padding-top: 0;
	}

	.section-9 {
		top: 0;
	}

	.ths01-search {
		width: 150px;
		display: none;
	}

	.mobile-menu-toggle-2 {
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-right: 0;
		display: block;
	}

	.logo-wrapper-2 {
		width: auto;
	}

	.nav-link.mobile {
		width: 80%;
	}

	.header-nav-inner-content {
		padding: 0.75rem 1rem;
	}

	.alert-box-close-button {
		margin-top: 0;
		right: 1rem;
	}

	.secondary-nav-icon {
		margin-top: 0;
		margin-right: 0;
	}

	.search-input-exit {
		margin-right: 0.25rem;
	}

	.div-block-101 {
		padding-left: 0;
	}

	.top-header-wrapper {
		border-left-style: none;
		align-items: center;
		margin-bottom: 0.5rem;
		margin-left: 0;
		padding-left: 0;
	}

	.search {
		width: auto;
	}

	.secondary-nav-icon-copy {
		height: 16px;
		margin-top: 0;
		margin-right: 0;
		padding-left: 5px;
	}

	.dropdown-icon.translate {
		background-size: 8px;
	}

	.search-bar-wrap {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.top-header-nav-wrapper {
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		padding-top: 0.5rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.search-input-form-2 {
		top: auto;
		right: auto;
	}

	.mobile-menu-arrow {
		width: 20%;
		position: relative;
	}

	.search-input {
		margin-right: 1rem;
	}

	.brand {
		width: 125px;
	}

	.alert-header-text {
		flex-direction: column;
		flex-shrink: 0;
		flex-basis: 100%;
		align-self: flex-start;
		align-items: flex-start;
		padding-right: 0;
	}

	.search-button-2:hover {
		background-color: #fcb31c;
	}

	.alert-header-content-wrapper {
		flex-wrap: wrap;
		margin-right: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.alert-header-inner-text {
		flex: 0 auto;
		max-width: none;
		margin-bottom: 0.5rem;
		margin-right: 2rem;
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.search-bar {
		display: none;
		top: 0;
	}

	.search-section {
		justify-content: flex-end;
		margin-bottom: 0;
		margin-right: 1rem;
	}

	.alert-header-container {
		align-items: center;
		padding-left: 1rem;
	}

	.top-header-nav-link {
		text-align: center;
		margin-bottom: 0.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
		font-size: 0.75rem;
	}

	.top-header-nav-link.translate {
		text-align: center;
		margin-bottom: 0;
	}

	.top-header-nav-link.last {
		text-align: center;
		margin-bottom: 0;
		margin-right: 0.5rem;
	}

	.div-block-152 {
		padding-left: 0;
	}

	.uui-dropdown-icon-3-copy-2 {
		margin-right: 10px;
	}

	.nav-links-wrapper.display-none {
		display: none;
	}

	.subnav-search-wrap-2 {
		width: 100%;
		margin-top: 76px;
	}

	.nav-head__logo-img {
		width: 180px;
	}

	.nav-head__logo-img.mobile-view {
		width: 200px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.nav-head__logo {
		max-width: none;
	}

	.nav-links {
		display: none;
	}

	.text-block-60 {
		font-size: 0.95rem;
	}

	.nav-links__item {
		font-size: 1rem;
	}

	.uui-navbar07_item-heading-4 {
		color: #00549d;
		font-size: 1rem;
	}

	.nav-head__container {
		padding-left: 0;
		padding-right: 20px;
	}

	.uui-navbar07_dropdown-link-4 {
		padding-left: 0;
	}

	.nav-head {
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		display: flex;
	}

	.div-block-154 {
		width: 100%;
	}

	.helpbutton {
		width: 100%;
		margin-top: 20px;
		margin-left: 0;
	}

	.help-dropdown_link {
		padding-left: 0;
	}

	.div-block-155 {
		flex-flow: column;
	}

	.titleline-copy.fe.center-copy {
		margin-left: 0;
	}

	.about-us__p-copy {
		width: 100%;
	}

	.otg-rates {
		margin-top: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.topbar-container-mobile {
		justify-content: center;
	}

	.topbar-container-mobile.display-none {
		display: flex;
	}

	.topbar-container-mobile.mobileonly {
		height: 50px;
		display: flex;
	}

	.subnav__link-wrapper-2-mobile {
		margin-bottom: 0;
	}

	.mobile-search {
		z-index: 600;
		align-self: flex-end;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-bottom: 9px;
		margin-right: 50px;
		padding-bottom: 0;
		display: flex;
		position: relative;
	}

	.search-input-2 {
		height: 30px;
		margin-right: -30px;
	}

	.search-button {
		background-size: 12px;
		width: 30px;
		height: 30px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 0;
	}

	.search-button:hover {
		background-size: 13px;
	}

	.searchicon {
		margin-left: 10px;
		margin-right: 10px;
	}

	.searchicon.mobile {
		flex-flow: column;
		margin-left: auto;
		margin-right: 0.75rem;
		padding-left: 5px;
		display: flex;
	}

	.search-button-icon {
		background-size: 28px;
		border-radius: 0;
		height: 20px;
		margin-top: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.search-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.exit-button-icon {
		background-size: 28px;
		border-radius: 0;
		height: 20px;
		margin-top: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.exit-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.search-content {
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		align-items: center;
		width: 100vw;
		height: auto;
		margin-top: 0;
		margin-left: 0%;
		margin-right: 0;
		padding: 20px 1.5rem;
		display: flex;
		top: 134px;
		left: auto;
		right: 0;
	}

	.search-input-3 {
		border: 1px solid #e7e7e7;
		height: 50px;
		padding-left: 5px;
		color: #000;
	}

	.search-button-3 {
		padding: 0.65rem 0;
		font-size: 0.8rem;
	}
}

#w-node-ed5ec56a-90ea-d278-e38f-cec9a6364568-44d19f1c,
#w-node-_1ef4660a-404e-eeed-4c96-70d0eb4165ac-44d19f1c,
#w-node-abde3a4e-7e14-aa51-d07a-59f0f34cb02d-44d19f1c,
#w-node-bb7db2c3-2757-9539-2e58-27decc97e33d-44d19f1c,
#w-node-_8a4aeb84-b5c2-cf2c-acef-b8a584ede5d0-44d19f1c {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ae51cdc6-a294-1589-90b3-aacf8970c2c5-8970c2c2 {
	justify-self: start;
}

#w-node-efba5f02-b197-1a63-e5ec-28b44a043089-44d19f1d,
#w-node-_8a897732-4b44-38d8-c91b-6cdae157e60f-44d19f1d,
#w-node-_92b69eb5-106e-7c46-5296-55b83da81797-44d19f1d {
	grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-ed5ec56a-90ea-d278-e38f-cec9a6364568-44d19f1d,
#w-node-_1ef4660a-404e-eeed-4c96-70d0eb4165ac-44d19f1d,
#w-node-abde3a4e-7e14-aa51-d07a-59f0f34cb02d-44d19f1d,
#w-node-bb7db2c3-2757-9539-2e58-27decc97e33d-44d19f1d,
#w-node-_8a4aeb84-b5c2-cf2c-acef-b8a584ede5d0-44d19f1d {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5bed0471-9d79-c72d-e7c1-0a57d0a9913d-44d19f1d,
#w-node-_5bed0471-9d79-c72d-e7c1-0a57d0a99150-44d19f1d,
#w-node-_5bed0471-9d79-c72d-e7c1-0a57d0a99154-44d19f1d {
	grid-area: span 1 / span 2 / span 1 / span 2;
}

@media screen and (max-width: 991px) {
	#w-node-ae51cdc6-a294-1589-90b3-aacf8970c2c5-8970c2c2 {
		grid-area: span 1 / span 3 / span 1 / span 3;
		justify-self: auto;
	}
}

@media screen and (max-width: 479px) {
	#w-node-ae51cdc6-a294-1589-90b3-aacf8970c2d2-8970c2c2,
	#w-node-ae51cdc6-a294-1589-90b3-aacf8970c2f1-8970c2c2 {
		grid-area: span 1 / span 3 / span 1 / span 3;
		justify-self: center;
	}

	#w-node-ae51cdc6-a294-1589-90b3-aacf8970c313-8970c2c2 {
		grid-area: span 1 / span 3 / span 1 / span 3;
	}
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e66911"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e6690a"] {
		opacity: 0;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e6690a"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e66911"] {
		opacity: 0;
	}
}

@media (max-width: 767px) and (min-width: 480px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e6690a"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e66911"] {
		opacity: 0;
	}
}

@media (max-width: 479px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e6690a"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="82ff8fa7-b35e-b72d-e65b-074394e66911"] {
		opacity: 0;
	}
}

.slideshow {
	margin-top: 0;
}

.loc__map {
	height: calc(100% + 50px);
	margin-top: -50px;
}

@media screen and (min-width: 992px) {
	.otg-cards__item-text > * + * {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 991px) {
	.page-container > * + * {
		margin-top: 2rem;
	}

	.slideshow {
		margin-top: 0;
	}

	.otg-cards__link {
		display: inline-flex;
	}
}

@media screen and (max-width: 767px) {
	.subnav__button {
		display: inline-flex;
	}
}

/*********************** Header **********************/
/*****************************************************/
.search-content {
	display: none;
}

.header-alert {
	display: none;
}

.header-divider {
	padding-top: 177px;
}

.header-alert-inner {
	color: #fff;
	background-color: #946f05;
	padding: 15px 15px;
	display: flex;
}

.header-alert-inner p,
.header-alert-inner li,
.header-alert-inner a,
.header-alert-inner a:hover,
.header-alert-inner span,
.header-alert-inner td,
.header-alert-inner h1,
.header-alert-inner h2,
.header-alert-inner h3,
.header-alert-inner h4,
.header-alert-inner h5,
.header-alert-inner h6 {
	color: #fff;
}

.close-header-alert {
	width: 20px;
	min-width: 20px;
	margin: 3px 10px 0px 10px;
}

.header-alert-content {
	flex-grow: 1;
}

.header-alert-content p:last-child {
	margin-bottom: 0px;
}

.nav-head.sticky .header-alert {
	display: none !important;
}

.fdic {
	grid-column-gap: 7px;
	grid-row-gap: 7px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	margin-left: 0;
	margin-right: 0;
	display: flex;
}

.fdic-signage {
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 39.76px;
	height: 16px;
}

.fdic-signage.black {
	background-image: none;
	width: 40px;
	height: auto;
}

.text-block-14 {
	color: #fff;
	font-size: 12.8px;
	font-style: italic;
	line-height: 13px;
	font-family: Roboto, sans-serif;
}

.text-block-14.black {
	color: #000;
	margin-top: 2px;
	font-weight: 400;
	line-height: 16px;
}

.div-block-156 {
	flex-flow: column;
	display: flex;
}

@media screen and (min-width: 992px) {
	.header-alert {
		width: calc(100% + 8vw);
		margin-left: -4vw;
	}
}

@media screen and (max-width: 991px) {
	.subnav-search-wrap {
		display: none;
	}

	.header-divider {
		padding-top: 132px;
	}

	.fdic {
		justify-content: flex-start;
		margin-left: 20px;
	}

	.fdic-signage.black {
		background-image: none;
		justify-content: center;
		width: auto;
		height: 16px;
		display: flex;
	}

	.text-block-14.black {
		width: 100%;
		line-height: 13px;
	}
}

@media screen and (max-width: 767px) {
	.header-divider {
		padding-top: 124px;
	}

	.fdic {
		justify-content: flex-start;
		align-items: center;
		margin-left: 16px;
	}
}

@media screen and (max-width: 479px) {
	.header-divider {
		padding-top: 145px;
	}

	.fdic {
		order: 1;
		width: auto;
		margin-left: 20px;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.fdic-signage.black {
		grid-column-gap: 7px;
		grid-row-gap: 7px;
		height: 24px;
	}

	.image-38 {
		width: 90px;
	}

	.text-block-14.black {
		text-align: left;
		width: auto;
	}
}
/***************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	margin-top: -1px;
}

.accesible-navigation-menu li.main-nav__link-wrap-2 .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	margin-top: 0px;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.main-nav__link-wrap-2 .container-wrapper ul {
	display: none;
	margin: 0px;
	width: 230px;
	list-style-type: none;
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 0.75rem;
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
	padding: 0.75rem;
}

.accesible-navigation-menu li.main-nav__link-wrap-2 .container-wrapper ul li {
	width: 100%;
	text-align: left;
}

.accesible-navigation-menu li.main-nav__link-wrap-2 .container-wrapper ul li a {
	font-family: Roboto, sans-serif;
	color: #00549d;
	font-size: 14px;
	font-weight: 700;
	display: inline-block;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	width: 100%;
	font-family: Montserrat, sans-serif;
}

.accesible-navigation-menu li.main-nav__link-wrap-2 .container-wrapper ul li a:hover {
	color: #946f05;
	background-color: #f9fafb;
}

.accesible-navigation-menu li.main-nav__link-wrap-2.open .container-wrapper,
.accesible-navigation-menu li.main-nav__link-wrap-2:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.main-nav__link-wrap-2.open .container-wrapper ul,
.accesible-navigation-menu li.main-nav__link-wrap-2:hover .container-wrapper ul {
	display: inline-block;
}

.navmenu-desktop li:last-child {
	margin-right: 0px;
}

@media (max-width: 1580px) {
	.main-nav__link-wrap-2 {
		margin: 0rem 10px 0rem;
		padding: 0.2rem 10px 0.5rem 0;
	}

	.fdic {
		margin-bottom: 5px;
	}
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #f9f9f9;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.mm-wrapper_opened .nav-head {
	z-index: 100 !important;
}

.mobile-navigation-menu li a.nav-a {
	font-family: Roboto, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
	line-height: 1;
	padding: 20px 25px;
	color: #00549d;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li a.active {
	color: #946f05;
	text-decoration: underline;
}

.mm-listitem:after {
	border-color: transparent;
}

.mm-panel {
	padding-top: 0px !important;
}

.mm-listitem_vertical > .mm-listitem__btn {
	border-color: transparent;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background-color: transparent;
}

.mm-btn_next:after {
	border-color: #666;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cbtext a {
	color: inherit !important;
}

.about-us__heading,
.cbtext h2,
.heading-2 {
	background-image: none;
	padding-top: 0;
}

.link-block-9 {
	background-color: transparent;
	padding: 0;
}

.heading-2 {
	font-family: Roboto, sans-serif;
	margin-bottom: 10px;
	letter-spacing: 0px;
}

.about-us__p-copy.cbtext h2 {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
}

.about-us__p-copy.cbtext h5 {
	font-size: 1rem;
	font-family: Roboto, sans-serif;
}

.otg-blurb.services.aboutus {
	margin-bottom: 0px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text {
	position: relative;
	width: 100%;
	height: 100%;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.slider-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.image-37 {
	width: 375px;
}

@media (max-width: 991px) {
	.image-37 {
		width: 290px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a:hover,
.breadcrumb-list li:last-child a {
	color: var(--new-gold);
	text-transform: none;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	line-height: 0.85rem;
	text-decoration: none;
	border-bottom: 1px silid transparent;
}

.left-nav-list-link.active,
.left-nav-nested-list-link.grandchild.active {
	color: var(--new-gold);
}

.left-nav-nested-list-link.great-grandchild.active {
	color: #8f6a05;
}

.left-nav-nested-list {
	border-top: 1px solid rgba(91, 91, 91, 0.15);
}

.left-nav-nested-list li:last-child {
	border-bottom: 0px;
}

.section-wrap.only-right .main-content-wrapper.outer,
.section-wrap.no-columns .main-content-wrapper.outer {
	padding: 0px;
}

.section-wrap.only-right .main-col {
	width: 72.1%;
}

.section-wrap.only-right .right-col {
	width: 26.9%;
}

@media (max-width: 991px) {
	.section-wrap.only-right .main-col {
		width: 100%;
	}

	.section-wrap.only-right .right-col {
		width: 100%;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-sidebar td img {
	max-width: none;
}

.right-sidebar .box {
	margin-bottom: 1rem;
}

.right-sidebar .box.tools {
	padding: 17px;
}

.right-sidebar .box p {
	font-size: 1rem;
	color: #525252;
	font-family: Open Sans, sans-serif;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	color: var(--black2);
	text-align: left;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	margin-top: 10px;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar a {
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.2) var(--blue);
	color: var(--blue);
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-decoration: none;
}

.right-sidebar a:hover {
	color: var(--blue);
	border-bottom-color: rgba(0, 0, 0, 0);
	font-family: Roboto, sans-serif;
}

@media (max-width: 991px) {
	.right-sidebar {
		padding-left: 0px;
		padding-right: 0px;
		border: 0px;
	}

	.inside-page-header-wrap.inside-right,
	.inside-page-header-wrap.inside-left-2 {
		padding-left: 0;
		padding-top: 24px;
	}
}

/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar .box {
	margin-bottom: 1rem;
	padding-right: 20px;
}

.left-sidebar .box p {
	font-size: 1rem;
	color: #525252;
	font-family: Open Sans, sans-serif;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.main-col a,
.left-sidebar a {
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.2) var(--blue);
	color: var(--blue);
	text-decoration: none;
}

.main-col a:hover,
.left-sidebar a:hover {
	color: var(--blue);
	border-bottom-color: rgba(0, 0, 0, 0);
}

h1 {
	color: #000;
	margin-top: 0;
	margin-bottom: 1.25rem;
	padding: 0;
	font-size: 48px;
	font-weight: 700;
	margin-bottom: 1rem;
}

h2 {
	color: var(--black2);
	margin-bottom: 10px;
	font-family: Roboto, sans-serif;
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.3rem;
	text-decoration: none;
	background-image: url("/assets/images/header-border.jpg");
	background-repeat: no-repeat;
	padding-top: 1rem;
	margin-bottom: 1rem;
}


h3 {
	color: var(--black2);
	margin-top: 10px;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
}

h4 {
	color: var(--black2);
	text-align: left;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 1rem;
}

h5 {
	color: var(--black2);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	margin-bottom: 1rem;
}

h6 {
	color: var(--black2);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	margin-bottom: 1rem;
}

h1 a {
	line-height: 56px;
}

.main-col h1 a,
.main-col h2 a,
h1 a,
h2 a {
	color: var(--black2);
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	font-family: Roboto, sans-serif;
	text-decoration: none;
	transition: all 0.2s;
}

.main-col h3 a,
h3 a {
	color: var(--black2);
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	font-family: Roboto, sans-serif;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
}

.main-col h4 a,
h4 a {
	color: var(--black2);
	text-align: left;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 700;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	line-height: 24px;
}

.main-col h5 a,
h5 a {
	color: var(--black2);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
}

.main-col h6 a,
h6 a {
	color: var(--black2);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(82, 82, 82, 0.2);
	margin-bottom: 1rem;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
}

.main-col h1 a:hover,
.main-col h2 a:hover,
.main-col h3 a:hover,
.main-col h4 a:hover,
.main-col h5 a:hover,
.main-col h6 a:hover,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: var(--new-gold);
	color: var(--new-gold);
	-webkit-text-stroke-color: var(--new-gold);
	background-color: rgba(0, 0, 0, 0);
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	.main-col h3 a,
	h3 a {
		font-size: 1.5rem;
	}

	.main-col h5 a,
	h5 a {
		font-size: 1.125rem;
	}
}

.main-col p {
	color: var(--off-black);
	margin-bottom: 1.5rem;
	padding-right: 20px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-decoration: none;
}

@media screen and (max-width: 479px) {
	.main-col p {
		font-size: 0.95rem;
		line-height: 1.35rem;
	}
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	border: 2px solid var(--blue);
	background-color: var(--blue) !important;
	color: #fff !important;
	text-align: center;
	letter-spacing: 0;
	text-transform: none;
	border-radius: 0;
	padding: 12px 32px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 0.95rem;
	text-decoration: underline;
	transition: all 0.3s, transform 0.3s;
	display: inline-block;
	cursor: pointer;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	border: 2px solid var(--blue);
	background-color: var(--white) !important;
	box-shadow: none;
	color: var(--blue) !important;
	text-decoration: none;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	border: 2px solid var(--new-gold);
	background-color: var(--new-gold) !important;
	color: #fff !important;
	text-align: center;
	letter-spacing: 0;
	text-transform: none;
	border-radius: 0;
	padding: 12px 32px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 0.95rem;
	text-decoration: underline;
	transition: all 0.3s, transform 0.3s, background-color 0.3s;
	display: inline-block;
	cursor: pointer;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	background-color: var(--white) !important;
	color: var(--new-gold) !important;
	text-decoration: none;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	color: #00549d !important;
	text-transform: uppercase;
	cursor: pointer;
	font-family: Roboto, sans-serif;
	font-size: 0.85rem;
	font-weight: 700;
	line-height: 1.5rem;
	font-size: 15px;
	font-weight: 500;
	line-height: 30px;
	text-decoration: none !important;
	transition: opacity 0.2s ease-in;
	background-color: #fff !important;
	border-bottom: 0px;
	background-image: url('/assets/images/arrow_blue.png');
	background-position: calc(100% - 35px) center;
	background-repeat: no-repeat;
	background-size: 17px;
	padding-right: 60px;
	transition: all 300ms ease;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: var(--blue) !important;
	background-color: #fff !important;
	background-position: calc(100% - 25px) center;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
	box-shadow: 0px 0px 10px 0px #ccc;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	color: var(--white);
	margin-bottom: 0;
	padding: 20px 20px;
	font-size: 28px;
	background-color: var(--blue);
	font-weight: bold;
	line-height: 30px;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 10px 15px;
	text-align: left;
	background-color: #f1f1f1;
	color: #000;
	font-weight: bold;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 10px 15px;
	color: var(--off-black);
	font-size: 16px;
	font-weight: normal;
	border-bottom: 1px solid #e4e4e4;
}

table.styled.striped tr:nth-child(even) {
	background: #ddd;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row-nested input[type="text"],
.inside-row-nested input[type="tel"],
.inside-row-nested input[type="password"],
.inside-row-nested input[type="email"],
.inside-row-nested select,
.inside-row-nested textarea,
.inside-row-nested input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
	background-color: #fff;
	margin-bottom: 10px;
}

.inside-row-nested textarea {
	resize: vertical;
}

.inside-row-nested label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row-nested input[type="text"],
	.inside-row-nested input[type="tel"],
	.inside-row-nested input[type="password"],
	.inside-row-nested input[type="email"],
	.inside-row-nested select,
	.inside-row-nested textarea,
	.inside-row-nested input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-width: 0;
	border-left-color: var(--gold);
	background-color: var(--new-gold);
	color: var(--white);
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 1rem 1.5rem;
	font-family: Roboto, sans-serif;
	font-size: 21px;
	font-weight: 700;
	line-height: 1.75rem;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

@media screen and (max-width: 479px) {
	blockquote {
		padding-left: 15px;
		padding-right: 15px;
	}
}

/********************** Footer **********************/
/****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields {
	padding: 0 0 0 200px;
}

.ui-form-container .ui-form-label {
	left: -210px;
	width: 200px;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media screen and (max-width: 720px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* rates */
#rates_module table.styled thead tr th {
	padding: 10px 10px;
	font-size: 19px;
	font-weight: 700;
	line-height: 20px;
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

/* faqs */
#faqs_module .cms_title h2 {
	margin-top: 0px;
	margin-bottom: 20px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

#jobs_module.cms_form .cms_label {
	line-height: 34px;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
	line-height: 1.7rem;
}


/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #00549d;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1.3rem;
	background-color: #fff;
	margin-top: 30px;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

#post-add-comment .ui-form-container .ui-form-fields {
	padding: 0;
}

.right-sidebar .box.posts-blocks.posts-blocks-rss-feeds a,
.right-sidebar .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}


.right-sidebar .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-sidebar .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-sidebar .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-sidebar .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 10px;
}

.right-sidebar .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 39px;
}

.right-sidebar .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 10px 7px 10px !important;
	background-color: #00549d !important;
}

/* gdpr */
#cookie-consent-form-container {
	max-height: 100vh;
}

#cookie-consent-form-container form .consent-header img {
	margin: 0 auto;
	display: block;
	float: none;
}

#cookie-consent-container.minimized {
	bottom: 10px;
	right: 10px;
}

#cookie-consent-container .consent-disclaimer a {
	border-bottom: 1px dotted #fff;
	color: #fff;
	text-decoration: none;
	font-weight: normal;
}

.consent-form-visible .nav-head {
	display: none;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-actions .consent-actions-container {
		align-items: center;
		flex-direction: row;
	}
}

@media (max-width: 767px) {
	#cookie-consent-container {
		font-size: 0.8rem;
		line-height: 1.1rem;
	}

	#cookie-consent-container {
		display: flex;
		flex-direction: column;
		padding-top: 30px;
	}

	#cookie-consent-container .consent-actions {
		order: 2;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		position: absolute;
		right: 5px;
		top: 7px;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}


/* Login form */
[role=radio] {
	display: block;
	position: relative;
	margin: 0.5em 0;
	padding-left: 25px;
	border: thin transparent solid;
	cursor: pointer;
}

/* This creates the visuals for the radio buttons */
[role=radio]::before,
[role=radio]::after {
	display: block;
	position: absolute;
	border-radius: 50%;
	content: '';
}

[role=radio]::before {
	top: 0;
	left: 0;
	width: 16px;
	height: 16px;
	border: 1px solid #000;
	background: #fff;
}

[role=radio][aria-checked=true]::after {
	top: 5px;
	left: 5px;
	border: 4px solid #000;
	content: '';
}

.obPodSection:not(.active) {
	display: none;
}

.obPodWrap {
	position: absolute;
	z-index: 0;
	top: 100px;
	right: 230px;
	display: none;
	opacity: 0;
	transition: all 0.5s;
}

.obPodWrap.active {
	display: block;
	opacity: 1;
	z-index: 100;
}

.obPod {
	padding: 1rem;
	background: #fff;
	max-width: 90vw;
	max-height: 90vh;
	overflow: auto;
	box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.75);
	border-radius: 7px;
	max-width: 600px;
	min-width: 280px;
}

.obPod.loaded {
	transition: 0.75s right ease-in;
}

.obPodSection.active {
	margin-top: 1em;
	padding-top: 1em;
	border-top: 2px solid;
}

.radioHeader {
	font-size: 1.4rem;
	font-weight: bold;
}

.obPod .h4 {
	padding-right: 1rem;
	font-weight: 700;
	color: #000;
}

.obPod .close-button {
	position: absolute;
	right: 1rem;
	top: 1rem;
	font-size: 1rem;
	width: 1em;
	line-height: 1em;
	text-align: center;
	cursor: pointer;
}

.obPod .close-button .close-button-icon {
	font-size: 2rem;
}

.hidden {
	height: 1px;
	width: 1px;
	margin: -1px;
	border: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
	position: absolute;
}

.BOLoginTable {
	width: 100%;
}

.BOLoginTable th,
.BOLoginTable td {
	display: block;
}

.BOLoginForgotPasswordLink {
	margin-top: 0.75em;
	display: inline-block;
}

.obPodWrap.active .obPod {
	right: 145px;
	right: calc(145px + 2vw);
}

.obPodWrap:focus-within .obPod {
	right: 145px;
	right: calc(145px + 2vw);
}

.obPodWrap:focus-within .obPod {
	transition: none;
}

.h-login {
	cursor: pointer;
}

.h-login[aria-pressed="true"] {
	color: #00549d !important;
	background: #fff;
	border-color: currentColor;
	border-width: 2px;
	border-style: solid;
	margin-left: 3px;
	margin-right: 1px;
	padding-right: 5px;
	padding-left: 5px;
}

.obPodSection .form-control {
	margin: 0.5em auto;
}

.obPodSection .form-control {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.obPodSection a {
	border-style: none none solid;
	border-width: 1.5px;
	border-color: rgba(82, 82, 82, 0.2) rgba(82, 82, 82, 0.2) var(--blue);
	color: var(--blue);
	text-decoration: none;
}

@media (max-width: 991px) {
	.obPodWrap {
		top: 60px;
		right: 30px;
	}

	.h-login {
		background: #fff;
		border-color: #fff;
		border-width: 2px;
		border-style: solid;
	}

	.h-login[aria-pressed=true] {
		margin-right: 5px;
		margin-left: 3px;
	}
}

@media (max-width: 767px) {
	.obPodWrap {
		top: 50px;
	}
}

@media (max-width: 479px) {
	.obPodWrap {
		right: calc((100% - 280px) / 2);
	}
}

a[id]:not([id=""]) {
	scroll-margin-top: 180px;
	scroll-padding-top: 180px;
}

/* Calculators */
#tvcMainCalculatorDivId label {
	font-size: 1rem;
	display: inline;
}

#tvcMainCalculatorDivId .DetailTable legend {
	font-size: 1.2rem;
	line-height: normal;
	font-weight: bold;
	padding-bottom: 1rem;
}

#tvcMainCalculatorDivId .DetailTable th {
	font-weight: bold;
	text-align: left;
}

#tvcMainCalculatorDivId .DetailTable th p {
	font-weight: bold !important;
}
